@use '../src/styles/Colors.scss' as *;
@use '../src/styles/Breakpoints.scss' as *;

::selection {
  background-color: $primary;
  color: $white;
}

*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;

}

@mixin fonts {
  font-feature-settings: 'clig' off, 'liga' off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
}

@mixin font-headlines {
  @include fonts;
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
}

// HTML Elements
body {
  @include fonts;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: $primary;

}

h1 {
  @include font-headlines;
  font-family: "Parafina-SemiWideXBold";
  font-size: 96px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -1.5px;
  @media only screen and (max-width: $tabletBig) {
    font-size: 72px;
  };
  @media only screen and (max-width: $mobile) {
    font-size: 60px;
  };
}

h2 {
  @include font-headlines;
  font-family: Parafina-SemiWideXBold;
  font-size: 60px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -0.5px;
  @media only screen and (max-width: $tabletBig) {
    font-size: 56px;
  };
  @media only screen and (max-width: $mobile) {
    font-size: 42px;
  };
}

h3 {
  @include font-headlines;
  font-family: Parafina-SemiWideXBold;
  font-size: 48px;
  font-weight: 900;
  line-height: 100%;
  @media only screen and (max-width: $tabletBig) {
    font-size: 42px;
  };
  @media only screen and (max-width: $mobile) {
    font-size: 36px;
  };
}

h4 {
  @include font-headlines;
  font-family: Parafina-SemiWideXBold;
  font-size: 40px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.25px;
  @media only screen and (max-width: $tabletBig) {
    font-size: 34px;
  };
  @media only screen and (max-width: $mobile) {
    font-size: 28px;
  };
}

h5 {
  @include font-headlines;
  font-family: Parafina-SemiWideBold;
  font-size: 28px;
  font-weight: 700;
  line-height: 100%;
  @media only screen and (max-width: $tabletBig) {
    font-size: 24px;
  };
  @media only screen and (max-width: $mobile) {
    font-size: 20px;
  };
}

p {
  margin: 0px;
}

button {
  border: none;
}

a {
  cursor: pointer;
  color: $primary;
  text-decoration: underline;
}

li a {
  text-decoration: none;
}

iframe {
  border: none;
}

// # CORE COMPONENTS

// ## Grid System
.container, .row, .container .row {
  width: inherit;
  margin: 0px auto;
}

div.row {
  max-width: 1180px;
  margin-bottom: 0px;
}

.row .col {
  @media only screen and (max-width: $mobile) {
    padding-top: 20px;
    hyphens: none;
  }
  
  padding: 0px 20px;
  margin-left: 0px;
  margin-right: 0px;
}

// ## default padding for page sections
.default-padding-top-bottom {
  padding-top: 96px;
  padding-bottom: 96px;
  @media only screen and (max-width: $tabletBig) {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  @media only screen and (max-width: $mobile) {
    padding-top: 42px;
    padding-bottom: 42px;
  }

}

.default-margin-top-bottom {
  margin-top: 96px;
  margin-bottom: 96px;
  @media only screen and (max-width: $tabletBig) {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  @media only screen and (max-width: $mobile) {
    margin-top: 42px;
    margin-bottom: 42px;
  }
}

// ## background and text colors for dark, light and white sections
.color-schema-dark {
  color: $white;
  background-color: $primary;
}

.color-schema-light {
  color: $primary;
  background-color: $secondary-light;

}

.color-schema-white {
  color: $primary;
  background-color: $white;
}

.color-schema-svg-dark {
  fill: $secondary-light;
  stroke: $secondary-light;
}

.color-schema-svg-light {
  fill: $secondary;
  stroke: $secondary;
}

.color-schema-svg-white {
  fill: $secondary-light;
  stroke: $secondary-light;
}

.subtitle {
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
  text-align: left;
}

.hidden {
  display: none;
}

// try to overwrite the default browser CSS for autofilled input fields
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* Override default yellow background */
  box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;

  /* Set text color */
  -webkit-text-fill-color: $primary !important;

  border: 1px solid #ccc !important;
}


// same as body, but for reusing in title!

// REMOVE
.body1 {
  @include fonts;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

// REMOVE
.body2 {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  text-align: left;
}

// REMOVE
.body-din {
  font-family: "Inter-Regular"; // Neue DIN should be used
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: center;
}

// REMOVE?
.button-medium {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: left;
}

// REMOVE?
.button-large {
  font-family: "Inter-Regular";
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-align: left;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.md-96 {
  font-size: 96px;
}

.material-icons {
  color: $secondary-dark
}

// Glide CSS, added to save a network hop
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box
}

.glide * {
  box-sizing: inherit
}

.glide__track {
  overflow: hidden
}

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
  @media only screen and (max-width: $mobile) {
    gap: 82.5px;// image width 165px/2
  }
}

.glide__slides--dragging {
  user-select: none
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent
}

.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none
}

.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none
}

.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none
}

.glide--rtl {
  direction: rtl
}

/*# sourceMappingURL=glide.core.min.css.map */
