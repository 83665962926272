@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.ref-wrapper {
  background-color: $secondary-light;
  padding-top: 96px;
  padding-bottom: 104px;
  // @media only screen and (max-width: $tabletBig) {
  //   margin: 64px 0px 32px 0px;
  // };
  // @media only screen and (max-width: $tabletBig) {
  //   margin: 64px 0px 60px 0px;
  // };
}

.ref-headline {
  margin-top: 0;
  margin-bottom: 64px;
  text-align: center;
  color: $primary;
  @media only screen and (max-width: $mobile) {
    margin-bottom: 32px;
  };
}

.ref-content-row img {
  width: 153px;
  height: 153px;
}

img.ref {
  width: 153px;
  height: 153px;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  @media only screen and (max-width: $mobile) {
    margin-bottom: 4px;
  };
  @media only screen and (max-width: $mobile) {
    width: 100px;
    height: 100px;
  };
}

.ref-content-row {
  margin: 0px 32px 61px 32px;
  display: flex;
  justify-content: space-between;
  &:last-child {
    margin: 0px 32px 0px 32px;
  }
}

.ref-image {
  display: flex;
  justify-content: center;
}