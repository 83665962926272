@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;


.journey-wrapper {
  padding: 96px 30px 96px 104px;
  @media only screen and (max-width: $mobile) {
    display: none;
  }
}

.journey-headline {
  padding: 0px 20px 144px 0px;
  text-align: center;
  color: $primary;
  @media only screen and (max-width: $tabletBig) {
    padding: 96px 20px 64px 20px;
  }
}

.timeline {
  color: $primary;
  ul{    
    li{
      background: $secondary;
      position: relative;
      margin: 0 auto;
      width: 5px;
      padding-bottom: 40px;
      list-style-type: none;
      
      &:last-child{
        padding-bottom: 7px;
      }
      
      &:before{
        content: '';
        background: $white;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        width: 20px;
        height: 20px;
        border: 3px solid $secondary;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }
      
      // .hidden {
      //   opacity: 1;
      // }
      
      .content {
        position: relative;
        top: 7px;
        width: 450px;
        padding: 20px;
        transition: opacity 500ms;
        h2{
          margin-top: -45px;
          margin-bottom: 16px;
          font-weight: 300;
          font-size: 48px;
          line-height: 57px;
          @media only screen and (max-width: $mobile) {
            font-size: 36px;
            line-height: 43px;
          }
        }
        p{
          background: $white;
          padding: 16px;
          #headline {
            color: $primary;
            font-size: 12px;
            line-height: 32px;
            letter-spacing: 3.3px;
            margin-bottom: 8px;
            @media only screen and (max-width: $mobile) {
              margin-bottom: 8px;
            }
          }
          #text {
            color: $darkGrey;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 32px;
          }
          #highlight {
            color: $darkGrey;
            background-color: $secondary;
            text-transform: uppercase;
            letter-spacing: 1px;
            text-align: center;
            padding: 16px;
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
            margin-bottom: 32px;
          }
        }
        &:before{
          content: '';
          background: $secondary;
          position: absolute;
          top: 0px;
          width: 38px;
          height: 5px;
        }
      }
      
      &:nth-child(odd) .content {
        left: 50px;
        h2 {
          text-align: left;
        }
        // background: pink;
        // background: -webkit-linear-gradient(-45deg, $lightGrey , $lightGrey);
        // background: linear-gradient(-45deg,  $lightGrey , $lightGrey);
      }
      
      &:nth-child(odd) .content:before {
        left: -38px;
      }
      
      &:nth-child(even) .content {
        h2 {
          text-align: right;
        }
        left: calc(-#{450px} - 45px);
        // background: $secondary;
        // background: -webkit-linear-gradient(-45deg, $lightGrey , $lightGrey);
        // background: linear-gradient(-45deg,  $lightGrey , $lightGrey);
      }
      
      &:nth-child(even) .content:before {
        right: -38px;
      }
    }
  }
}

@media screen and (max-width: $tabletBig) {
  .timeline ul li .content {
    width: 41vw;
  }
  .timeline ul li:nth-child(even) .content {
    left: calc(-41vw - 45px);
  }
}

@media screen and (max-width: $tablet) {
  .timeline ul li {
    margin-left: 20px;
    
    .content {
      width: 80vw;
    }
    
    &:nth-child(even) .content {
      left: 45px;
      h2 {
        text-align: left;
      }
      // background: $secondary;
      // background: -webkit-linear-gradient(-45deg, $secondary , $secondary);
      // background: linear-gradient(-45deg,  $secondary , $secondary);
    }
    
    &:nth-child(even) .content:before {
      left: -33px;
    }
  }
}

.collapse-header {
 cursor: pointer;
}

.details {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  border-radius: 15px;
}

.details.fadein {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

.updown {

  &:nth-child(odd) {
    left: -38px;
  }

  &:nth-child(even) {
    left: 38px;
  }

}