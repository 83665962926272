@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

nav {
  background-color: $darkBlueNav;
  padding-left: 200px;
  padding-right: 200px;
}

nav ul li {
  transition: unset;
  -webkit-transition: unset;
  -moz-transition: unset;
  -ms-transition: unset;
  -o-transition: unset;
  &:hover,
  &:focus {
    border-bottom: 2px solid #01EFDD;
    margin-bottom: -2px;
  }
  border: none;
  text-decoration: none;
}

nav ul a {
  transition: unset;
  -webkit-transition: unset;
  -moz-transition: unset;
  -ms-transition: unset;
  -o-transition: unset;
  border: none;
  text-decoration: none;
  outline-style: none;
}

.navbar {
  &__item {
    color: $white;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    border: none;
    outline-style: none;
    &:hover,
    &:focus {
      border-bottom: 2px solid #01EFDD;
      margin-bottom: -2px;
    }
  }

  &__icon {
    padding-top: 5px;
  }
}

.sidenav {
  left: -20px;
  color: $white;
  background-color: $darkBlueNav;
}

.sidenav li>a {
  color: $white;
}
#nav-solutions {
  min-width: 250px;
}

#nav-resources {
  min-width: 250px;
}

#nav-search {
  min-width: 500px;
}

////////// as in materialize //////////
$navbar-height: 56px;
@media only screen and (max-width: $mobile){
  nav {
    color: #fff;
    width: 100%;
    height: $navbar-height;
    line-height: $navbar-height;
  }

  .search-mobile {
    margin-top: $navbar-height;
  }
}
////////////////////////////////////////

.dropdown-content  {
  background-color: unset !important;
}

.dropdown-content li > a {
  background-color: $darkBlueNav;
  color: $white;
}

.dropdown-content li:hover,
.dropdown-content li.active {
  background-color: $darkBlueNav;
  border-bottom: 2px solid #01EFDD;
  margin-bottom: -2px;
}

.dropdown-content li {
  clear:both;
  color:$primary-dark;
  cursor:pointer;
  min-height:50px;
  line-height:1.5rem;
  width:100%;
  text-align:left
}

.btn-search-mobile {
  text-align: center;
  width: 1.5rem;
  display: none;

  @media only screen and (max-width: $mobile) {    
    display: unset;
  }
}

.btn-search {
  width: 3rem; /* to dont have a change in width when clicking on/off */
  @media only screen and (max-width: $mobile) {
    display: none;
  }
}

nav .sidenav-trigger {
  display: contents;
}

@media only screen and (max-width: $mobile) {
  nav .brand-logo {
    left: unset;
    -webkit-transform: unset;
    transform: unset;
    display: contents;
  }
}

@media only screen and (max-width: $mobile) {  
  nav .nav-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
}

.btn-search-open {
  background-color: $darkBlueNav;
}

.btn-search-close {
  background-color: $darkBlueNav;
}

.dropdown-trigger {
  border: none !important;
  text-decoration: none !important;
  &:hover,
  &:focus {
    border: none !important;
    text-decoration: none !important;
  }
}

nav .sidenav-trigger {
  right: 5%;
  position: fixed;
}

@media only screen and (min-width: 994px) and (max-width: 1400px) {

  nav {
    padding-right: 40px;
    padding-left: 40px;
  }
}

nav i, nav [class^="mdi-"], nav [class*="mdi-"], nav i.material-icons {
  display: inline-block;
  color: $secondary-light;
}
