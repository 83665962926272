@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.quotes-container {
  @media only screen and (max-width: $tabletBig) {
    display: none;
  };
}

ul li .quotes-wrapper {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.quotes-quote {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.quotes-headline {
  margin-bottom: 26px;
  text-align: center;
}

.quotes-right-icon {
  transform: rotate(180deg);
  min-width: 50px;
  margin-right: 32px;
  margin-bottom: 100px;
  align-self: flex-end;
}

.quotes-left-icon {
  margin-left: 32px;
  min-width: 50px;
}

.quotes-text {
  padding: 0px 20px;
  color: $black;
  text-align: center;
}

.quotes-author-name {
  text-align: center;
  margin-top: 64px;
  font-size: 18px;
  line-height: 27px;
  color: $primary-dark;
}

.quotes-author-title, .quotes-author-company {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: $primary-dark;
}

.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px !important;
  .slides li {
    overflow: initial;
  }
}

li.indicator-item {
  background-color: white !important;
  border: 1px solid $secondary-dark;
}
  
li.indicator-item.active {
  background-color: $secondary-dark !important;
}