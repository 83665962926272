@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.company-team-wrapper {
  background-color: $primary;
  padding-top: 96px;
  padding-bottom: 104px;
  @media only screen and (max-width: $tabletBig) {
    padding: 64px 32px;
  }
  @media only screen and (max-width: $mobile) {
    padding: 32px 24px;
  }
}

.company-team-headline {
  margin-bottom: 10px;
  text-align: center;
  color: $white;
  @media only screen and (max-width: $tabletBig) {
    font-size: 36px;
    line-height: 43px;
  }
  @media only screen and (max-width: $mobile) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 0px;
  }
}

.company-team-profile {
  margin-top: 54px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: $mobile) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

img.profile-pic {
  max-width: 160px;
  max-height: 160px;
  margin: auto;
  @media only screen and (max-width: $mobile) {
    height: 121px;
    width: auto;
  }
}

img.profile-linkedIn {
  &:hover,
  &:focus,
  &:active {
    opacity: 0.8
  }
}

.profile-name {
  margin-top: 32px;
  text-align: center;
  color: $white;
  @media only screen and (max-width: $tabletBig) {
    margin-top: 8px;
    // letter-spacing: 0.67px;
  }
  @media only screen and (max-width: $mobile) {
    // font-size: 14px;
    // line-height: 20px;
    margin-top: 16px;
  }
}

.profile-position {
  margin-top: 8px;
  text-align: center;
  color: $white;
  @media only screen and (max-width: $tabletBig) {
    margin-top: 0px;
  }
  // @media only screen and (max-width: $mobile) {
  //   font-size: 14px;
  //   line-height: 20px;
  // }
}

.profile-link {
  text-align: center;
  margin-top: 16px;
  @media only screen and (max-width: $mobile) {
    margin-top: 8px;
  }
}