@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.whitepapers-wrapper {
  padding: 0px 32px 0px 32px;
  @media only screen and (max-width: $mobile) {
    padding: 32px 13.5px 0px 13.5px;
  };
}

.whitepapers-image {
  max-width: 230px;
  width: 100%;
  height: auto;
}
  
.whitepapers-headline {
  color: $primary;
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  @media only screen and (max-width: $mobile) {
    font-size: 20px;
    letter-spacing: 0.67px;
    margin: 8px 0px;
  };
}
  
.whitepapers-text {
  color: $darkGrey;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 27px;
  height: 80px;
  @media only screen and (max-width: $tabletBig) {
    height: unset;
  };
  @media only screen and (max-width: $mobile) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  };
}

.whitepapers-download {
  display: flex;
  justify-content: center;
}

.whitepapers-button {
  margin-bottom: 32px;
  padding: 14px 20px;
  border-radius: 3px;
  background-color: $primary-light;
  display: flex;
  width: fit-content;
  cursor: pointer;
  justify-content: center;
  &:hover {
    transition: 0.3s ease-in-out;
    background-color: $primary;
  };
  &:active {
    background-color: $primary;
  }
  @media only screen and (max-width: $mobile) {
    justify-content: center;
    width: 100%;
  };
}

.whitepapers-button-text {
  color: $white;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  margin-left: 18px;
}