@use '../../styles/Colors' as *;

.img-class {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
}


// REMOVE
/*.divider-shape-color-light {
  @extend .image-on-dark-bg;
}*/

/*.filter-icons-color {
  @extend .image-on-dark-bg;
}*/

/*.divider-shape-color {
  @extend .image-on-light-bg-2;
}*/

.arrows-bg {
  background-color: $secondary-light;
}
