@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.hero-wrapper {
  padding: 156px;
  background-color: $white;
  width: 100%;
  @media only screen{// and (max-width: $mobile) {
    padding: 10px;
  }
}

.hero-image {
  // margin-top: 32px;
  // background-image: url('../../images/hero-static.svg'), url('../../images/hero-background.svg');
  // background-size: 100% 100%;
  // background-repeat: no-repeat, no-repeat;
  // @media only screen and (max-width: $mobile) {
  //   background-image: url('../../images/hero-animation-mobile.svg'), url('../../images/hero-background.svg');
  // }
}

.hero-image-content {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;

  // only on mobile?
  margin-left: -200px;
}

.hero-row-empty-mobile {
  display: none;
  @media only screen and (max-width: $mobile) {
    display: block;
    height: 100px;
  }
}

.hero-col-wrapper {
  max-width: 800px;

  @media only screen and (max-width: $tabletBig) {
    margin-left: 12px;
  }
  @media only screen and (max-width: $mobile) {
    margin: 0px 0px;
    max-width: unset;
  }
}

div.col.s12.m5.l6 {
  @media only screen and (max-width: $xl) and (min-width: $tabletBig) {
    margin-left: 0px;
  }
  @media only screen {
    margin-top: 32px;
  }
}

.hero-text-wrapper-headline {
  text-align: left;
  margin-bottom: 32px;
  word-break: normal;
  hyphens: none;  
  
  @media only screen and (max-width: $tabletBig) {
    margin-top: 32px;
  }
  @media only screen and (max-width: $mobile) {
    margin-top: -72px;
    text-align: center;
    font-size: 48px;
  }
}

.hero-text-wrapper-text {
  margin-bottom: 32px;
  color: $primary;
  @media only screen and (max-width: $tabletBig) {
    margin-right: 40px;
    margin-bottom: 32px;
  }
  @media only screen and (max-width: $mobile) {
    text-align: center;
    margin-right: unset;
    margin-bottom: 32px;
  }
}

.hero-button-wrapper {
  margin-bottom: 18px;
  display: flex;
  @media only screen and (max-width: $tabletBig) {
    margin-bottom: 92px;
  }
  @media only screen and (max-width: $mobile) {
    margin-bottom: 32px;
  }
}

.hero-certificate-wrapper img {
  width: 70%;
  padding-bottom: 48px;
  @media only screen and (max-width: $tabletBig) {
    display: none;
  }
}

.hero-certificate-wrapper-hidden {
  display: none;
  @media only screen and (max-width: $tabletBig) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 16px 0px;
  }
  @media only screen and (max-width: $mobile) {
    margin: 32px 0px;
    flex-direction: column;
    padding: 0px;
    img {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}