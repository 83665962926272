@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.blog-box {
  &__wrapper {
    padding: 128px 0;
    @media only screen and (max-width: $tabletBig) {
      padding: 64px 0;
    }
    @media only screen and (max-width: $mobile) {
      padding: 0;
    }
  }

  &__postsyoulike {
    padding: 0px 0;
    @media only screen and (max-width: $tabletBig) {
      padding: 0px 0;
    }
    @media only screen and (max-width: $mobile) {
      padding: 0;
    }
  }

  &__headline, &__headline-overview {
    color: $primary;
    text-align: center;
    padding: 64px 0;
    font-weight: 300;
    @media only screen and (max-width: $mobile) {
      padding-top: 64px;
      padding-bottom: 12px;
    }
  }
  
  &__headline {
    font-size: 24px;
    line-height: 30px;
  }
  
  &__headline-overview {
    font-size: 48px;
    line-height: 57px;
  }

  &__container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  
  &__box {
    padding-bottom: 32px;
    background-color: $lightGrey;
    display: flex;
    flex: 1 1 300px;
    flex-direction: column;
    max-width: 500px;
    margin-bottom: 12px;
    text-decoration: none;
    color: $primary;
    line-height: 27px;
    font-size: 18px;
    cursor: pointer;

    &:nth-child(2) {
      margin: 0 112px 12px;
    }
    @media only screen and (max-width: $tabletBig) {
      &:nth-child(2) {
        margin: 0;
      }
      max-width: 100%;
      margin-bottom: 12px;
    }

    &-content {
      &-headline {
        font-weight: bold;
        padding: 32px 32px 0 32px;
      }
      &-info {
        font-size: 12px;
        line-height: 32px;
        letter-spacing: 3.3px;
        padding: 0 32px;
      }
      &-text {
        margin-bottom: 24px;
        padding: 0 32px;
      }
    }
  }
  
  &-info-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 32px;
    margin-top: auto;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 15px;
    font-weight: 700;
  }
  
  &__box-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    -webkit-transition: .3s ease-in-out;
    transition: all .3s ease-in-out;
    &:hover {
      -webkit-filter: grayscale(0);
      filter: grayscale(0);
    }
  }
  
  &-button {
    margin-top: 64px;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: $mobile) {
      margin: 32px 24px;
    }
  }
}

img.blog-box__box-image {
  max-height: auto;
  max-width: 100%;
  object-fit: cover;
  object-position: top;
  display: block;
  transition: all .3s ease-in-out;
  text-decoration: none;
  &:hover {
    transform: scale(1.05);
  }
  @media only screen and (max-width: $tabletBig) {
    width: 100%;
    max-height: auto;
  }
}