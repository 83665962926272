@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.securityinfo-wrapper {
  background: $white;
  padding-top: 128px;

  @media only screen and (max-width: $tabletBig) {
    padding-top: 64px;
  }

  ;
}

.securityinfo-icon-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  @media only screen and (max-width: $mobile) {
    img {
      max-height: 100px;
      max-width: 100px;
    }
  }

  ;
}

.securityinfo-text-wrapper {
  padding: 0px 12px;
  margin-bottom: 64px;
  text-align: center;
}

.security-bg-wrapper {  
  text-align: center;

  @media only screen and (max-width: $tabletBig) {
    max-width: $tabletBig;
  }
}

.securityinfo-headline {
  color: $primary;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  text-align: center;
  margin-bottom: 16px;

  @media only screen and (max-width: $tabletBig) {
    margin-bottom: 8px;
  }

  ;

  @media only screen and (max-width: $tabletBig) {
    margin-bottom: px;
    font-size: 20px;
    letter-spacing: 0.67px;
  }

  ;
}

.securityinfo-content {
  text-align: center;

  @media only screen and (max-width: $tabletBig) {
    font-size: 16px;
    line-height: 24px;
  };
}