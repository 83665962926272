@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

/*.filter-icons-color {
  @extend .image-on-dark-bg;
}*/


.services-landing-background {
  background: $primary-light;
  @media only screen and (max-width: $tabletBig) {
    background: $primary-light;
  }
}

.security-detail-dark,
.security-detail-light,
.security-detail-dark-mobile,
.security-detail-light-mobile {
  height: 286px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  @media only screen and (max-width: $tabletBig) {
    height: 170px;
  }
  @media only screen and (max-width: $mobile) {
    height: 155px;
  }
}

// REMOVE
/*.services-icons {
  color: $secondary-light !important;
}*/

.security-detail-icon {
  color: $secondary-light;
  padding-top: 55px;
  margin-bottom: 16px;
  @media only screen and (max-width: $tabletBig) {
    padding-top: 27px;
    margin-bottom: 8px;
    img {
      height: 60px;
      width: 60px;
    }
  }
  @media only screen and (max-width: $mobile) {
    padding-top: 22px;
  }
}

.services-headline {
  margin-bottom: 64px;
  margin-top: 0px;
  color: $white;
  @media only screen and (max-width: $tabletBig) {
    margin-bottom: 32px;
  }
}

.security-detail-title {
  color: $white;
  text-align: center;
  @media only screen and (max-width: $tabletBig) {
  }
  @media only screen and (max-width: $mobile) {
  }
}

.security-button {
  margin-top: 76px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: $mobile) {
    padding: 32px 24px;
    width: 100%;
  }
}
