@use '../../styles/Colors.scss' as *;
@use '../../styles/Breakpoints' as *;

#email-input, #search {
  color: $primary,
}

input#email, input#search {
  padding: 0px;
  border-bottom: 2px solid $secondary !important;
  @media only screen and (max-width: $mobile) {
    margin-bottom: 22px;
  };
}

input.invalid[type=email]:not(.browser-default),
input[type=email]:not(.browser-default):focus:not([readonly]),
input.valid[type=email]:not(.browser-default) {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

div.input-field.col {
  padding: 0px 21px 0px 0px;
  @media only screen and (max-width: $mobile) {
    padding: 0px; 
  };
}

.input-field.col label {
  left: 0px;
}

.input-field input:focus + label {
  color: $secondary !important;
}

.input-field {
  color: $secondary !important;
}

// initial display states

.nl-processing {
  display: none;
}

.nl-thanks {
  display: none;
}

.nl-feedback{
  text-align: left;
}