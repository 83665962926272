@charset "UTF-8";
@media only screen and (max-width: 600px) {
  .imprint-wrapper {
    padding: 32px 24px;
  }
}

.imprint-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .imprint-headline {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
    margin-bottom: 16px;
  }
}

.imprint-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .imprint-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.imprint-listitem li::before {
  content: "•";
  margin-left: -20px;
  padding-right: 13px;
}