@use '../../styles/Colors.scss' as *;
@use '../../styles/Breakpoints' as *;

.prefooter-container {
  background-color: $primary;
}

.prefooter-row {
  padding: 64px 32px;
  @media only screen and (max-width: $tabletBig) {
    padding: 0px 16px 32px 16px;
  };
}

.prefooter-content-wrapper {
  padding: 0px;
  @media only screen and (max-width: $tabletBig) {
    margin: 32px 0px;
  };
  @media only screen and (max-width: $mobile) {
    margin: 16px 0px;
  };
};

.prefooter-col-content {
  @media only screen and (max-width: $tabletBig) and (min-width: $mobile) {
    padding: 0 12px;
  }
}

.prefooter-headline {
  color: $white;
  padding-bottom: 24px;
}

.prefooter-content {
  margin: 8px 0px 0px 0px;
  @media only screen and (max-width: 600px) {
    margin-top: 8px;
  };
}

.prefooter-content-text {
  color: $white;
  padding-top: 4px;
  margin-bottom: 32px;
  @media only screen and (max-width: $tabletBig) and (min-width: $mobile) {
    padding-top: 8px;
    margin-bottom: 12px;
  };
}

.prefooter-content.icons img {
  padding: 8px 32px 8px 0;
  &:hover, &:focus, &:active {
    opacity: 0.9
  }
}

.prefooter-logo {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  @media only screen and (max-width: $mobile) {
    display: none;
  }
}