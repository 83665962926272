@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.licensing-wrapper, .licensing-wrapper-expanded {
  padding-top: 96px;  
  padding-bottom: 104px;
}

.licensing-headline {
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  padding-bottom: 48px;
  color: $primary;
  // @media only screen and (max-width: $tabletBig) {
  //   padding-top: 64px;
  //   padding-bottom: 32px;
  // };
}

.licensing-subheadline {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 64px;
  font-size: 24px;
  line-height: 57px;
  font-weight: 300;
  color:  $primary;
  // @media only screen and (max-width: $tabletBig) {
  //   padding-top: 64px;
  //   padding-bottom: 32px;
  // };
}

.licensing-buttons {
  text-align: left;  
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px;
  transition: 1s ease border-width;
  @media only screen and (max-width: $mobile) {
    display: none;
  };
}

.licensing-button-active, .licensing-button {
  text-transform: capitalize;
  border: 2px solid $primary;
  letter-spacing: 1.5px;
  font-weight: bold;
}

.licensing-button-active {
  padding: 18px 20px;
  font-size: 14px;
  line-height: 14px;
  background-color: $primary;
  color: $white;
}

.licensing-button {
  padding: 15px 20px;
  font-size: 14px;
  line-height: 20px;
  background-color: $lightGrey;
  color: $primary;
  &:hover {
    padding: 18px 20px;
    font-size: 14px;
    line-height: 14px;
    background-color: $primary;
    color: $white;
  }
}

.licensing-editions-wrapper {
  background-color: $white;
  @media only screen and (max-width: $tabletBig) {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0px 22px 32px 22px;
    // background-color: unset;
  }
  // @media only screen and (max-width: $tabletBig) {
  //   margin-bottom: 0px;
  // };
}

.licensing-right-border {
  border-right: 3px solid $lightGrey;
  margin: 64px 0px;
  &:last-child {
    border-right: unset;
  }
  @media only screen and (max-width: $tabletBig) {
    margin: 0px 32px 32px 32px;
    border-right: unset;
  };
}

.licensing-edition {
  padding: 0px 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: $tabletBig) {
    padding: 32px 25px;
  }
  @media only screen and (max-width: $mobile) {
    padding: 16px 0;
  }
}

.licensing-editions-headline {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 16px;
  margin-bottom: 32px;
  color: $primary-dark;
  @media only screen and (max-width: $mobile) {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
    padding-top: 16px;
    padding-bottom: 4px;
    };
}

.licensing-editions-text-wrapper {
  height: 240px;
  @media only screen and (max-width: $tabletBig) {
    height: unset;
    max-width: 500px;
  };
}

img.licensing-icon {
  @media only screen and (max-width: $mobile) {
    height: 60px;
  };
}

.licensing-editions-text {
  list-style-type: disc !important;
  padding: 0px 18px 16px 18px;
  color: $primary;
  text-align: left;
  @media only screen and (max-width: $tabletBig) {
    padding-bottom: 8px;
  };
  @media only screen and (max-width: $mobile) {
    font-size: 16px;
    line-height: 24px;
  };
}

.licensing-editions-button {
  margin-top: 32px;
}

.licensing-info-wrapper {
  display: none;
  @media only screen and (max-width: $mobile) {
    display: block;
    margin-top: 32px;
  };
}

.licensing-info-text {
  @media only screen and (max-width: $mobile) {
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
    color: $primary;
    text-align: center;
  };
}

.licensing-bottom-button-wrapper {
  margin-top: -54px;
  padding-bottom: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: $tabletBig) {
    margin-top: 32px;
    padding-bottom: 64px;
  };
  @media only screen and (max-width: $mobile) {
    margin: unset;
    padding: 32px 24px;
  };
}

.licensing-comparison-wrapper {
  padding-top: 96px;
  padding-bottom: 104px;
  @media only screen and (max-width: $tabletBig) {
    margin: 0px 32px;
  };
  @media only screen and (max-width: $mobile) {
    background-color: $white;
    margin: 0px 24px;
  };
}

.licensing-comparison-headline {
  cursor: pointer;
  margin-top: 0;
  padding-bottom: 64px;
  text-align: center;
  color: $primary;
  @media only screen and (max-width: $tabletBig) {
    padding: 64px 24px;

  };
  @media only screen and (max-width: $mobile) {
    padding-bottom: 0px;
    margin-bottom: -16px;
  };
}