@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.certified-wrapper {
  padding: 128px 0px;
  background-color: $secondary-light;
  @media only screen and (max-width: $tabletBig) {
    padding: 64px 0px;
  }
}

.certified-row-wrapper {
  padding-bottom: 72px;
  @media only screen and (max-width: $mobile) {
    padding-bottom: 0px;
  }
}

.certified-headline {
  margin-bottom: 64px;
  text-align: center;
  color: $primary;
  @media only screen and (max-width: $mobile) {
    margin-bottom: 32px;
  }
}

.certified-col-img {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  @media only screen and (max-width: $tabletBig) {
    height: unset;
    width: unset;
  }
}

.certified-col-headline {
  margin-top: 32px;
  margin-bottom: 16px;
  text-align: center;
  color: $primary;
  @media only screen and (max-width: $tabletBig) {
    margin-top: 16px;
  }
  @media only screen and (max-width: $mobile) {
    margin-bottom: 8px;
  }
}

.certified-col-text {
  text-align: center;
  @media only screen and (max-width: $tabletBig) {
    margin: 0px 16px;
  }
  @media only screen and (max-width: $mobile) {
    margin-bottom: 64px;
    margin-left: auto;
    margin-right: auto;
  }
}