@use '../styles/Colors' as *;
@use '../styles/Breakpoints' as *;

.contact-wrapper {
  padding: 64px 32px;
  @media only screen and (max-width: $mobile) {
    padding: 32px 24px;
  }
}

.company-information-container {
  border-right: 3px solid $lightGrey;
  margin-right: 40px;
  padding-right: 32px;
  @media only screen and (max-width: $tabletBig) {
    border-right: unset;
    margin-right: 0;
    padding-right: 0;
  }
}

.company-information-header {
  font-size: 24px;
  text-align: left;
  margin-bottom: 16px;
  letter-spacing: 0.8px;
  @media only screen and (max-width: $tabletBig) {
    text-align: center;
  }
  @media only screen and (max-width: $tabletBig) {
    line-height: 20px;
    letter-spacing: 0.67px;
  }
}

.company-information-text {
  font-size: 18px;
  text-align: left;
  line-height: 27px;
  color: $darkGrey;
  padding-bottom: 48px;
  word-break: break-all;
  @media only screen and (max-width: $tabletBig) {
    text-align: center;
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
}

.contact-info-container {
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: $tabletBig) {
    border-top: 3px solid $lightGrey;
    border-left: none;
    padding-top: 32px;
    padding-left: 0;
  }
}

.contact-tech-wrapper, .contact-info-wrapper, .contact-message-wrapper, .contact-newsletter-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  &:last-child {
    margin-bottom: 0;
  }
  @media only screen and (max-width: $mobile) {
    margin-bottom: 32px;  
  }
}

.contact-information-headline {
  color: $primary;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 3.3px;
  text-transform: uppercase;
  padding-bottom: 30px;
  @media only screen and (max-width: $mobile) {
    padding-bottom: 0;  
  }
}

.input-field-label {
  color: $primary !important;
}

input#first_name,
input#last_name,
input#job_title,
input#company,
input#address,
input#zipcode,
input#city,
input#email2,
input#phone_number,
input#website,
input#country,
input#elasticsearch_version,
input#current_stage,
textarea#message,
input#organization,
input#tls-email,
input#host1,
input#host2,
input#host2,
input#host3,
input#host4,
input#host5,
input#host6,
input#host7,
input#host8,
input#host9,
input#host10 {
  border-bottom: 2px solid $primary;
  box-shadow: none;
}

.privacy-policy {	
  margin: 32px 0px;
}

.drop-down-container.col.s12.m6 {
  padding: 0;
}

#contact {
  @media only screen and (max-width: $mobile) {
    padding: 0;
  }
}

// initial display states

.cf-feedback {
  display: none;
}

.cf-feedback-text {
  text-align: left;
  color: $primary;
}
