@use '../../../styles/Colors' as *;
@use '../../../styles/Breakpoints' as *;

.texttile-icon-wrapper {
  display: flex;
  justify-content: center;
}

.texttile-content-wrapper {
  @media only screen and (max-width: $mobile) {
    padding-bottom: 64px;
    padding-top: 0px;
    text-align: center;
  };
}

.texttile-headline {
  margin-bottom: 32px;
  margin-top: 0px;
  @media only screen and (max-width: $mobile) {
    // font-size: 16px;
    text-align: center;
  };
}

.tile-row {
  padding-top: 80px;
  padding-bottom: 96px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;

  @media only screen and (max-width: $mobile) {
    flex-direction: column;
    padding-bottom: 0px;
    padding-top: 0px;
  };
}