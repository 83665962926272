@charset "UTF-8";
.flex-center {
  justify-content: center;
  justify-items: center;
  display: flex;
}

.row.blogpostarticle-wrapper {
  padding: 0 12px;
  text-wrap: pretty;
  word-break: break-word;
}

.row.blogpostarticle-author {
  margin-top: 32px;
  font-size: 18px;
  font-weight: 300;
}

.blogpostarticle-avatar {
  border-radius: 50%;
}

.blogpostarticle-headline1 {
  color: #184962;
  margin-top: 64px;
  margin-bottom: 32px;
  font-size: 48px;
  font-weight: 300;
  line-height: 57px;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-headline1 {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .blogpostarticle-headline1 {
    font-size: 24px;
    margin-top: 32px;
  }
}

.blogpostarticle-headline2 {
  margin-top: 48px;
  margin-bottom: 32px;
  color: #184962;
  font-size: 32px;
  letter-spacing: 0.8px;
  line-height: 30px;
  text-align: left;
}

.blogpostarticle-headline3 {
  margin-top: 32px;
  margin-bottom: 16px;
  color: #184962;
  font-size: 24px;
  letter-spacing: 0.8px;
  line-height: 30px;
}

.blogpostarticle-code-snippet {
  background-color: #E8ECED;
  font-family: Courier;
  font-size: 16px;
  line-height: 19px;
  padding: 16px;
  word-break: break-all;
  overflow: scroll;
  margin-top: 24px;
  margin-bottom: 24px;
}

.blogpostarticle-quote-wrapper {
  margin: 26px 30px 30px -15vw;
  max-width: 425px;
  float: left;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-quote-wrapper {
    margin-left: 0px;
    float: none;
    max-width: unset;
  }
}
@media only screen and (max-width: 600px) {
  .blogpostarticle-quote-wrapper {
    margin-right: 0px;
  }
}

.blogpostarticle-quote-image {
  float: left;
}
@media only screen and (max-width: 600px) {
  .blogpostarticle-quote-image {
    width: 26px;
    height: 29px;
  }
}

.blogpostarticle-quote-text .blogpostarticle-text {
  margin: 0px;
  max-width: 360px;
  margin-left: 70px;
  color: #184962;
  padding: 16px 0px;
  font-size: 24px;
  letter-spacing: 0.8px;
  line-height: 27px;
  border-top: 10px solid #E8ECED;
  border-bottom: 10px solid #E8ECED;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-quote-text .blogpostarticle-text {
    max-width: 85%;
  }
}
@media only screen and (max-width: 600px) {
  .blogpostarticle-quote-text .blogpostarticle-text {
    font-size: 18px;
    line-height: 20px;
    max-width: 100%;
    margin-left: 40px;
  }
}

.blogpostarticle-image-wrapper.blogpostarticle-image {
  display: block;
  margin: auto;
  width: auto;
  height: auto;
  max-height: 500px;
  max-width: 100%;
  margin-top: 30px;
}

.blogpostarticle-image-description {
  color: #184962;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.blogpostarticle-text {
  margin-bottom: 32px;
  margin-top: 32px;
  color: #184962;
  font-size: 18px;
  line-height: 27px;
}
@media only screen and (max-width: 600px) {
  .blogpostarticle-text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.blogpostarticle-sidebar-container {
  margin: 64px 0px;
  border-top: 6px solid #E8ECED;
  border-bottom: 6px solid #E8ECED;
  max-width: 68px;
  position: sticky;
  top: 64px;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-sidebar-container {
    position: unset;
    max-width: 100%;
  }
}

.blogpostarticle-sidebar-icons-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-sidebar-icons-container {
    flex-direction: row;
    border-bottom: 6px solid #E8ECED;
    border: unset;
  }
}

.blogpostarticle-sidebar-title {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  max-width: 68px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 3.3px;
  color: #184962;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-sidebar-title {
    display: none;
  }
}

.blogpostarticle-sidebar-icon {
  margin: 8px 16px;
  cursor: pointer;
}
.blogpostarticle-sidebar-icon:last-child {
  margin-bottom: 16px;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-sidebar-icon:last-child {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-sidebar-icon {
    display: flex;
  }
}
.blogpostarticle-sidebar-icon img:hover, .blogpostarticle-sidebar-icon img:focus, .blogpostarticle-sidebar-icon img:active {
  opacity: 0.8;
}

.blogpostarticle-share-icon {
  width: 32px;
  height: 32px;
  fill: #184962;
}

.blogpostarticle-recommended-headline {
  margin: 124px 0px 64px 0px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  text-align: center;
  color: #184962;
}

.bold {
  font-weight: bold;
  display: inline;
}

.underline {
  text-decoration: underline;
  display: inline;
}

.italic {
  font-style: italic;
  display: inline;
}

.blogpostarticle-listitem {
  color: #184962;
  font-size: 18px;
  line-height: 27px;
}
@media only screen and (max-width: 600px) {
  .blogpostarticle-listitem {
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.blogpostarticle-listitem:before {
  content: "•";
  margin-left: -20px;
  padding-right: 13px;
}