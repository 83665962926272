@use '../../styles/Colors.scss' as *;
@use '../../styles/Breakpoints' as *;

.security-wrapper {
  margin: 32px 32px;
  @media only screen and (max-width: $mobile) {
    margin: 32px 24px;
  };
}

.security-headline {
  color: $primary;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  padding-top: 32px;
  margin-bottom: 32px;
  @media only screen and (max-width: $mobile) {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
    margin-bottom: 16px;
  };
}

.security-text {
  font-family: 'Courier New', Courier, monospace;
  word-wrap: break-word;
  background-color: $lightGrey;
  padding: 16px;
  color: $darkGrey;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 32px;
  @media only screen and (max-width: $mobile) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  };
}

.security-plain-text {
  color: $darkGrey;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 96px;
  @media only screen and (max-width: $mobile) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  };
  
}