@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.trusted-wrapper {
  padding: 128px;
  background-color: $secondary-light;
  @media only screen and (max-width: $tabletBig) {
    padding: 64px 32px 0px 32px;
  }
  @media only screen and (max-width: $mobile) {
    padding: 32px 24px 16px 24px;
  }
}

.trusted-headline {
  margin-bottom: 64px;
}

.trusted-pic {
  display: flex;
  justify-content: center;
  margin: 30px 20px;
}