@use '../../styles/Colors.scss' as *;
@use '../../styles/Breakpoints.scss' as *;

.drop-down-container {
  cursor: pointer;
  border-bottom: 2px solid $primary;
  margin-top: 16px;
  margin-right: 21px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding-left: 0;
}

.drop-down-wrapper.col.s12.m6 {
  padding-left: 0;
  @media only screen and (max-width: $mobile) {
    padding: 0;
    margin-bottom: 16px;
  }
}

.drop-down-label {
  position: absolute;
  top: -10px;
}

.drop-down-text-field {
  font-family: Roboto;	
  font-size: 15px;
  color: $primary;
  line-height: 22px;
  padding: 0px;
  margin: 12px 0;
  @media only screen and (max-width: $tabletBig) {
    line-height: 15px;
  }
}

.drop-down-open-and-close-button {
  width: 10px; 
  transition-duration: 0.3s;
  position: absolute;
  right: 1px;
}

.drop-down-close-button {
  transform: rotate(180deg);	
}

.drop-down-options-container {
  position: absolute;
  top: 47px;
  left: 0px;
  color: $primary;
  width: 100%;
  background-color: white;
  z-index: 1;
}

select.browser-default {
  border: unset;
  border-bottom: 2px solid $primary;
  color: $primary;
  padding: 10px;
  &:hover {
    background-color: $primary;
    color: white;
  }
}
