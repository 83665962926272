@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin: 128px 32px;
}

li.pagination-number {
  a {
    padding: 14px 18px;
    font-size: 18px;
    color: $secondary;
    font-weight: bold;
    cursor: pointer;
    &:focus {
      border-bottom: 2px solid $secondary-dark;
    }
  }
}