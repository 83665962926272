::selection {
  background-color: #184962;
  color: #FFF;
}

*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

body {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #184962;
}

h1 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: "Parafina-SemiWideXBold";
  font-size: 96px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -1.5px;
}
@media only screen and (max-width: 992px) {
  h1 {
    font-size: 72px;
  }
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 60px;
  }
}

h2 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: Parafina-SemiWideXBold;
  font-size: 60px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -0.5px;
}
@media only screen and (max-width: 992px) {
  h2 {
    font-size: 56px;
  }
}
@media only screen and (max-width: 600px) {
  h2 {
    font-size: 42px;
  }
}

h3 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: Parafina-SemiWideXBold;
  font-size: 48px;
  font-weight: 900;
  line-height: 100%;
}
@media only screen and (max-width: 992px) {
  h3 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 600px) {
  h3 {
    font-size: 36px;
  }
}

h4 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: Parafina-SemiWideXBold;
  font-size: 40px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.25px;
}
@media only screen and (max-width: 992px) {
  h4 {
    font-size: 34px;
  }
}
@media only screen and (max-width: 600px) {
  h4 {
    font-size: 28px;
  }
}

h5 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: Parafina-SemiWideBold;
  font-size: 28px;
  font-weight: 700;
  line-height: 100%;
}
@media only screen and (max-width: 992px) {
  h5 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 600px) {
  h5 {
    font-size: 20px;
  }
}

p {
  margin: 0px;
}

button {
  border: none;
}

a {
  cursor: pointer;
  color: #184962;
  text-decoration: underline;
}

li a {
  text-decoration: none;
}

iframe {
  border: none;
}

.container, .row, .container .row {
  width: inherit;
  margin: 0px auto;
}

div.row {
  max-width: 1180px;
  margin-bottom: 0px;
}

.row .col {
  padding: 0px 20px;
  margin-left: 0px;
  margin-right: 0px;
}
@media only screen and (max-width: 600px) {
  .row .col {
    padding-top: 20px;
    -webkit-hyphens: none;
            hyphens: none;
  }
}

.default-padding-top-bottom {
  padding-top: 96px;
  padding-bottom: 96px;
}
@media only screen and (max-width: 992px) {
  .default-padding-top-bottom {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}
@media only screen and (max-width: 600px) {
  .default-padding-top-bottom {
    padding-top: 42px;
    padding-bottom: 42px;
  }
}

.default-margin-top-bottom {
  margin-top: 96px;
  margin-bottom: 96px;
}
@media only screen and (max-width: 992px) {
  .default-margin-top-bottom {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}
@media only screen and (max-width: 600px) {
  .default-margin-top-bottom {
    margin-top: 42px;
    margin-bottom: 42px;
  }
}

.color-schema-dark {
  color: #FFF;
  background-color: #184962;
}

.color-schema-light {
  color: #184962;
  background-color: #B0F8F2;
}

.color-schema-white {
  color: #184962;
  background-color: #FFF;
}

.color-schema-svg-dark {
  fill: #B0F8F2;
  stroke: #B0F8F2;
}

.color-schema-svg-light {
  fill: #02F0DD;
  stroke: #02F0DD;
}

.color-schema-svg-white {
  fill: #B0F8F2;
  stroke: #B0F8F2;
}

.subtitle {
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
  text-align: left;
}

.hidden {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* Override default yellow background */
  box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  /* Set text color */
  -webkit-text-fill-color: #184962 !important;
  border: 1px solid #ccc !important;
}

.body1 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.body2 {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  text-align: left;
}

.body-din {
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: center;
}

.button-medium {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: left;
}

.button-large {
  font-family: "Inter-Regular";
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-align: left;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.md-96 {
  font-size: 96px;
}

.material-icons {
  color: #00B7A8;
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
@media only screen and (max-width: 600px) {
  .glide__slides {
    grid-gap: 82.5px;
    gap: 82.5px;
  }
}

.glide__slides--dragging {
  -webkit-user-select: none;
          user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  -webkit-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.glide__slide a {
  -webkit-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.glide__arrows {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}

.glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}

.glide--rtl {
  direction: rtl;
}


.badge-style {
  display: inline-flex;
  padding: 0.2em 0.5em;
  font-size: 0.8em;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border-radius: 0.25rem;
  height: 2rem;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.div-search-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.div-search-button-close {
  cursor: pointer;
  position: relative;
  right: 15px;
  height: -moz-fit-content;
  height: fit-content;
  background: none;
  filter: invert(100%) sepia(0%) saturate(7460%) hue-rotate(125deg) brightness(111%) contrast(110%);
}

.div-search {
  width: 100%;
  padding: 2rem;
  background-color: #246E94 !important;
  overflow: auto;
  max-height: 0;
  padding: 2rem;
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.2); /* Shadow */
  border-radius: 0 0 15px 15px; /* Rounded corners */
  position: relative;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 600px) {
  .div-search {
    left: 0;
    right: 0;
    width: -webkit-fill-available;
    margin: 0 20px 0 20px;
    position: absolute;
    margin-right: 30px;
  }
}

.div-search-collapse-animation {
  padding: 0 2rem 0 2rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms linear, padding 500ms linear, overflow 500ms linear;
}

.div-search-expand-animation {
  max-height: 80vh;
  overflow: auto;
  transition: max-height 500ms linear, padding 500ms linear, overflow 500ms linear;
}
@media only screen and (max-width: 600px) {
  .div-search-expand-animation {
    padding: 2rem;
  }
}

.div-search::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  position: absolute; /* Keeps it relative to the container */
}

.div-search::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.4); /* Scrollbar thumb color */
  border-radius: 15px; /* Rounded scrollbar */
}

.div-search::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4); /* Hover effect for scrollbar thumb */
}

.div-search::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Scrollbar track color */
  border-radius: 15px; /* rounded scrollbar track*/
  margin-bottom: 10px;
}

.div-search-index-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.div-search-index-menu-item {
  display: block;
  margin: 5px 0;
}

.div-search input[type=radio]:not(:checked) + span:before, .div-search input[type=radio]:checked + span:before,
.div-search input[type=radio]:not(:checked) + span:after, .div-search input[type=radio]:checked + span:after {
  display: none;
}

.div-search [type=radio]:not(:checked) + span, .div-search [type=radio]:checked + span {
  padding: 0.2em 0.5em;
  display: unset;
}

.div-search input[type=radio]:not(:checked), .div-search input[type=radio]:checked {
  appearance: auto; /* Resets browser styles to default */
  -webkit-appearance: radio; /* For Safari */
  margin: 0;
  width: auto; /* Use browser-defined size */
  width: 16px;
  height: 16px;
  position: unset;
  opacity: unset;
  pointer-events: none;
  cursor: pointer;
}

.sidenav li > a > i, .sidenav li > a > [class^=mdi-], .sidenav li > a li > a > [class*=mdi-], .sidenav li > a > i.material-icons {
  color: unset;
}

.div-search input label {
  font-size: 16px;
  cursor: pointer;
}

.div-search-text {
  color: #FFF;
  background-color: #246E94;
}

.div-close-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10px;
}

.div-search .ais-Hits-list {
  list-style-type: none; /* Removes default numbering */
}

.div-search .ais-Menu {
  margin-bottom: 20px;
}

.globalsearchresult-item {
  display: flex;
  flex-direction: column;
  margin: 0px 20px 64px 20px;
}
@media only screen and (max-width: 600px) {
  .globalsearchresult-item {
    margin: 16px 4px;
  }
}

.globalsearchresult-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.globalsearchresult-title-group {
  grid-gap: 10px;
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.globalsearchresult-title,
.globalsearchresult-result-headline {
  color: #FFF;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
}
@media only screen and (max-width: 600px) {
  .globalsearchresult-title,
  .globalsearchresult-result-headline {
    font-size: 20px;
    letter-spacing: 0.67px;
  }
}

.globalsearchresult-result-headline {
  padding: 0px 20px 32px 20px;
  font-weight: bold;
  text-align: center;
}

.globalsearchresult-author {
  color: #FFF;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 3.3px;
  margin-bottom: 8px;
}

.globalsearchresult-content {
  color: #FFF;
  font-size: 18px;
  line-height: 27px;
}

.truncate-to-num-lines {
  display: -webkit-box; /* establish a block formatting context */
  -webkit-box-orient: vertical; /* set the box orientation to vertical */
  line-clamp: 3; /* limit the text to n lines */
  -webkit-line-clamp: 3; /* limit the text to n lines */
  overflow: hidden; /* ensure content outside is hidden */
  text-overflow: ellipsis; /* add an ellipsis (...) at the end */
}
nav {
  background-color: #133346;
  padding-left: 200px;
  padding-right: 200px;
}

nav ul li {
  transition: unset;
  -webkit-transition: unset;
  -moz-transition: unset;
  -ms-transition: unset;
  -o-transition: unset;
  border: none;
  text-decoration: none;
}
nav ul li:hover, nav ul li:focus {
  border-bottom: 2px solid #01EFDD;
  margin-bottom: -2px;
}

nav ul a {
  transition: unset;
  -webkit-transition: unset;
  -moz-transition: unset;
  -ms-transition: unset;
  -o-transition: unset;
  border: none;
  text-decoration: none;
  outline-style: none;
}

.navbar__item {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  border: none;
  outline-style: none;
}
.navbar__item:hover, .navbar__item:focus {
  border-bottom: 2px solid #01EFDD;
  margin-bottom: -2px;
}
.navbar__icon {
  padding-top: 5px;
}

.sidenav {
  left: -20px;
  color: #FFF;
  background-color: #133346;
}

.sidenav li > a {
  color: #FFF;
}

#nav-solutions {
  min-width: 250px;
}

#nav-resources {
  min-width: 250px;
}

#nav-search {
  min-width: 500px;
}

@media only screen and (max-width: 600px) {
  nav {
    color: #fff;
    width: 100%;
    height: 56px;
    line-height: 56px;
  }
  .search-mobile {
    margin-top: 56px;
  }
}
.dropdown-content {
  background-color: unset !important;
}

.dropdown-content li > a {
  background-color: #133346;
  color: #FFF;
}

.dropdown-content li:hover,
.dropdown-content li.active {
  background-color: #133346;
  border-bottom: 2px solid #01EFDD;
  margin-bottom: -2px;
}

.dropdown-content li {
  clear: both;
  color: #184962;
  cursor: pointer;
  min-height: 50px;
  line-height: 1.5rem;
  width: 100%;
  text-align: left;
}

.btn-search-mobile {
  text-align: center;
  width: 1.5rem;
  display: none;
}
@media only screen and (max-width: 600px) {
  .btn-search-mobile {
    display: unset;
  }
}

.btn-search {
  width: 3rem; /* to dont have a change in width when clicking on/off */
}
@media only screen and (max-width: 600px) {
  .btn-search {
    display: none;
  }
}

nav .sidenav-trigger {
  display: contents;
}

@media only screen and (max-width: 600px) {
  nav .brand-logo {
    left: unset;
    transform: unset;
    display: contents;
  }
}
@media only screen and (max-width: 600px) {
  nav .nav-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
}
.btn-search-open {
  background-color: #133346;
}

.btn-search-close {
  background-color: #133346;
}

.dropdown-trigger {
  border: none !important;
  text-decoration: none !important;
}
.dropdown-trigger:hover, .dropdown-trigger:focus {
  border: none !important;
  text-decoration: none !important;
}

nav .sidenav-trigger {
  right: 5%;
  position: fixed;
}

@media only screen and (min-width: 994px) and (max-width: 1400px) {
  nav {
    padding-right: 40px;
    padding-left: 40px;
  }
}
nav i, nav [class^=mdi-], nav [class*=mdi-], nav i.material-icons {
  display: inline-block;
  color: #B0F8F2;
}
.footer-container {
  background-color: #184962;
}

.footer-wrapper {
  color: #FFF;
}

div.row.footer-row {
  padding: 40px 0px;
}

.footer-links-column-container {
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .footer-links-column-container {
    margin-bottom: 0px;
  }
}

.footer-list-items {
  margin-bottom: 64px;
}

a.footer-links {
  text-decoration: none;
  color: #FFF;
}

.footer-list-headline a, .footer-list-item a {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  margin-left: 4.75px;
  color: #FFF;
}
.footer-list-headline a:hover, .footer-list-item a:hover {
  color: rgba(255, 255, 255, 0.85);
}
@media only screen and (max-width: 600px) {
  .footer-list-headline a, .footer-list-item a {
    line-height: 48px;
  }
}

.footer-list-headline {
  margin-bottom: 32px;
  font-weight: bold;
  text-transform: capitalize;
}
@media only screen and (max-width: 600px) {
  .footer-list-headline {
    margin-bottom: 0px;
    position: relative;
    text-align: center;
    top: -16px;
  }
}

.footer-list-item {
  margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
  .footer-list-item {
    text-align: center;
    margin-bottom: 0px;
  }
}
.footer-list-item:last-child {
  position: relative;
  top: 21px;
  margin-top: -21px;
}
@media only screen and (max-width: 600px) {
  .footer-list-item:last-child {
    top: 32px;
    margin-top: -32px;
  }
}

.footer-text {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 32px 64px 32px;
  color: #FFF;
}
@media only screen and (max-width: 600px) {
  .footer-text {
    padding-bottom: 32px;
  }
}

.footer-content-wrapper {
  padding: 0px;
}
@media only screen and (max-width: 992px) {
  .footer-content-wrapper {
    margin: 32px 0px;
  }
}
@media only screen and (max-width: 600px) {
  .footer-content-wrapper {
    margin: 16px 0px;
  }
}

@media only screen and (max-width: 992px) and (min-width: 600px) {
  .footer-col-content {
    padding: 0 12px;
  }
}

.footer-headline {
  color: #FFF;
}
@media only screen and (max-width: 600px) {
  .footer-headline {
    text-align: center;
    margin-bottom: 0px;
  }
}

.footer-content {
  margin: 24px 0px 0px 0px;
}
@media only screen and (max-width: 600px) {
  .footer-content {
    margin-top: 8px;
  }
}

.footer-content-icons {
  display: flex;
  justify-content: flex-start;
  grid-gap: 32px;
  gap: 32px;
  align-content: center;
  align-items: center;
}

hr.footer-hr {
  display: none;
  height: 1.75px;
  background-color: #FFF;
  margin-top: 24px;
}
@media only screen and (max-width: 600px) {
  hr.footer-hr {
    display: block;
  }
}
.button {
  text-decoration: none;
}

.button-default-container {
  background-color: #02F0DD;
  height: 48px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  padding: 14px 20px;
  cursor: pointer;
}
.button-default-container:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.28);
  transition: box-shadow 0.1s linear;
}
.button-default-container:active {
  background-color: #02F0DD;
}
@media only screen and (max-width: 600px) {
  .button-default-container {
    width: 100%;
  }
}
.hero-wrapper {
  padding: 156px;
  background-color: #FFF;
  width: 100%;
}
@media only screen {
  .hero-wrapper {
    padding: 10px;
  }
}

.hero-image-content {
  width: 100%;
  width: -webkit-fill-available;
  margin-left: -200px;
}

.hero-row-empty-mobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .hero-row-empty-mobile {
    display: block;
    height: 100px;
  }
}

.hero-col-wrapper {
  max-width: 800px;
}
@media only screen and (max-width: 992px) {
  .hero-col-wrapper {
    margin-left: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .hero-col-wrapper {
    margin: 0px 0px;
    max-width: unset;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  div.col.s12.m5.l6 {
    margin-left: 0px;
  }
}
@media only screen {
  div.col.s12.m5.l6 {
    margin-top: 32px;
  }
}

.hero-text-wrapper-headline {
  text-align: left;
  margin-bottom: 32px;
  word-break: normal;
  -webkit-hyphens: none;
          hyphens: none;
}
@media only screen and (max-width: 992px) {
  .hero-text-wrapper-headline {
    margin-top: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .hero-text-wrapper-headline {
    margin-top: -72px;
    text-align: center;
    font-size: 48px;
  }
}

.hero-text-wrapper-text {
  margin-bottom: 32px;
  color: #184962;
}
@media only screen and (max-width: 992px) {
  .hero-text-wrapper-text {
    margin-right: 40px;
    margin-bottom: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .hero-text-wrapper-text {
    text-align: center;
    margin-right: unset;
    margin-bottom: 32px;
  }
}

.hero-button-wrapper {
  margin-bottom: 18px;
  display: flex;
}
@media only screen and (max-width: 992px) {
  .hero-button-wrapper {
    margin-bottom: 92px;
  }
}
@media only screen and (max-width: 600px) {
  .hero-button-wrapper {
    margin-bottom: 32px;
  }
}

.hero-certificate-wrapper img {
  width: 70%;
  padding-bottom: 48px;
}
@media only screen and (max-width: 992px) {
  .hero-certificate-wrapper img {
    display: none;
  }
}

.hero-certificate-wrapper-hidden {
  display: none;
}
@media only screen and (max-width: 992px) {
  .hero-certificate-wrapper-hidden {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 16px 0px;
  }
}
@media only screen and (max-width: 600px) {
  .hero-certificate-wrapper-hidden {
    margin: 32px 0px;
    flex-direction: column;
    padding: 0px;
  }
  .hero-certificate-wrapper-hidden img {
    margin-bottom: 16px;
  }
  .hero-certificate-wrapper-hidden img:last-child {
    margin-bottom: 0px;
  }
}
.texttile-icon-wrapper {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .texttile-content-wrapper {
    padding-bottom: 64px;
    padding-top: 0px;
    text-align: center;
  }
}

.texttile-headline {
  margin-bottom: 32px;
  margin-top: 0px;
}
@media only screen and (max-width: 600px) {
  .texttile-headline {
    text-align: center;
  }
}

.tile-row {
  padding-top: 80px;
  padding-bottom: 96px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}
@media only screen and (max-width: 600px) {
  .tile-row {
    flex-direction: column;
    padding-bottom: 0px;
    padding-top: 0px;
  }
}
.securityinfo-wrapper {
  background: #FFF;
  padding-top: 128px;
}
@media only screen and (max-width: 992px) {
  .securityinfo-wrapper {
    padding-top: 64px;
  }
}

.securityinfo-icon-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .securityinfo-icon-wrapper img {
    max-height: 100px;
    max-width: 100px;
  }
}

.securityinfo-text-wrapper {
  padding: 0px 12px;
  margin-bottom: 64px;
  text-align: center;
}

.security-bg-wrapper {
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .security-bg-wrapper {
    max-width: 992px;
  }
}

.securityinfo-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  text-align: center;
  margin-bottom: 16px;
}
@media only screen and (max-width: 992px) {
  .securityinfo-headline {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 992px) {
  .securityinfo-headline {
    margin-bottom: px;
    font-size: 20px;
    letter-spacing: 0.67px;
  }
}

.securityinfo-content {
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .securityinfo-content {
    font-size: 16px;
    line-height: 24px;
  }
}
.columnedtile {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* Headline of this component */
.columnedtile-wrapper-headline {
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .columnedtile-wrapper-headline {
    margin-bottom: 32px;
  }
}

/* Ensure the row behaves as a flex container so all columns are the same height and buttons are at the bottom of each column */
.columnedtile-row {
  display: flex;
  flex-wrap: wrap;
}

/* Make columns stretch to the same height */
.columnedtile-row .col {
  display: flex;
  flex-direction: column;
}

.columnedtile-wrapper {
  background: #FFF;
  padding-top: 128px;
}
@media only screen and (max-width: 992px) {
  .columnedtile-wrapper {
    padding-top: 64px;
  }
}

.columnedtile-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .columnedtile-container {
    padding-top: 32px;
  }
}

.columnedtile-icon-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .columnedtile-icon-wrapper img {
    max-height: 100px;
    max-width: 100px;
  }
}

.columnedtile-text-wrapper {
  margin-bottom: 64px;
}
@media only screen and (max-width: 992px) {
  .columnedtile-text-wrapper {
    margin-bottom: 32px;
  }
}

.security-bg-wrapper {
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .security-bg-wrapper {
    max-width: 992px;
  }
}

/* Headline of this column */
h2.columnedtile-headline {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  text-align: center;
  margin-bottom: 16px;
}
@media only screen and (max-width: 992px) {
  h2.columnedtile-headline {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 992px) {
  h2.columnedtile-headline {
    margin-bottom: px;
    font-size: 20px;
    letter-spacing: 0.67px;
  }
}

.columnedtile-content {
  text-align: center;
  flex: 1 1;
}
@media only screen and (max-width: 992px) {
  .columnedtile-content {
    font-size: 16px;
    line-height: 24px;
  }
}

/*.columnedtile-icons {
  @extend .image-on-light-bg;
}*/
.columnedtile-button-container {
  margin-top: auto;
  height: auto;
}
.trusted-wrapper {
  padding: 128px;
  background-color: #B0F8F2;
}
@media only screen and (max-width: 992px) {
  .trusted-wrapper {
    padding: 64px 32px 0px 32px;
  }
}
@media only screen and (max-width: 600px) {
  .trusted-wrapper {
    padding: 32px 24px 16px 24px;
  }
}

.trusted-headline {
  margin-bottom: 64px;
}

.trusted-pic {
  display: flex;
  justify-content: center;
  margin: 30px 20px;
}
.prefooter-container {
  background-color: #184962;
}

.prefooter-row {
  padding: 64px 32px;
}
@media only screen and (max-width: 992px) {
  .prefooter-row {
    padding: 0px 16px 32px 16px;
  }
}

.prefooter-content-wrapper {
  padding: 0px;
}
@media only screen and (max-width: 992px) {
  .prefooter-content-wrapper {
    margin: 32px 0px;
  }
}
@media only screen and (max-width: 600px) {
  .prefooter-content-wrapper {
    margin: 16px 0px;
  }
}

@media only screen and (max-width: 992px) and (min-width: 600px) {
  .prefooter-col-content {
    padding: 0 12px;
  }
}

.prefooter-headline {
  color: #FFF;
  padding-bottom: 24px;
}

.prefooter-content {
  margin: 8px 0px 0px 0px;
}
@media only screen and (max-width: 600px) {
  .prefooter-content {
    margin-top: 8px;
  }
}

.prefooter-content-text {
  color: #FFF;
  padding-top: 4px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 992px) and (min-width: 600px) {
  .prefooter-content-text {
    padding-top: 8px;
    margin-bottom: 12px;
  }
}

.prefooter-content.icons img {
  padding: 8px 32px 8px 0;
}
.prefooter-content.icons img:hover, .prefooter-content.icons img:focus, .prefooter-content.icons img:active {
  opacity: 0.9;
}

.prefooter-logo {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
@media only screen and (max-width: 600px) {
  .prefooter-logo {
    display: none;
  }
}
#email-input, #search {
  color: #184962;
}

input#email, input#search {
  padding: 0px;
  border-bottom: 2px solid #02F0DD !important;
}
@media only screen and (max-width: 600px) {
  input#email, input#search {
    margin-bottom: 22px;
  }
}

input.invalid[type=email]:not(.browser-default),
input[type=email]:not(.browser-default):focus:not([readonly]),
input.valid[type=email]:not(.browser-default) {
  box-shadow: unset;
}

div.input-field.col {
  padding: 0px 21px 0px 0px;
}
@media only screen and (max-width: 600px) {
  div.input-field.col {
    padding: 0px;
  }
}

.input-field.col label {
  left: 0px;
}

.input-field input:focus + label {
  color: #02F0DD !important;
}

.input-field {
  color: #02F0DD !important;
}

.nl-processing {
  display: none;
}

.nl-thanks {
  display: none;
}

.nl-feedback {
  text-align: left;
}
/*.filter-icons-color {
  @extend .image-on-dark-bg;
}*/
.services-landing-background {
  background: #478EB3;
}
@media only screen and (max-width: 992px) {
  .services-landing-background {
    background: #478EB3;
  }
}

.security-detail-dark,
.security-detail-light,
.security-detail-dark-mobile,
.security-detail-light-mobile {
  height: 286px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
@media only screen and (max-width: 992px) {
  .security-detail-dark,
  .security-detail-light,
  .security-detail-dark-mobile,
  .security-detail-light-mobile {
    height: 170px;
  }
}
@media only screen and (max-width: 600px) {
  .security-detail-dark,
  .security-detail-light,
  .security-detail-dark-mobile,
  .security-detail-light-mobile {
    height: 155px;
  }
}

/*.services-icons {
  color: $secondary-light !important;
}*/
.security-detail-icon {
  color: #B0F8F2;
  padding-top: 55px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 992px) {
  .security-detail-icon {
    padding-top: 27px;
    margin-bottom: 8px;
  }
  .security-detail-icon img {
    height: 60px;
    width: 60px;
  }
}
@media only screen and (max-width: 600px) {
  .security-detail-icon {
    padding-top: 22px;
  }
}

.services-headline {
  margin-bottom: 64px;
  margin-top: 0px;
  color: #FFF;
}
@media only screen and (max-width: 992px) {
  .services-headline {
    margin-bottom: 32px;
  }
}

.security-detail-title {
  color: #FFF;
  text-align: center;
}
.security-button {
  margin-top: 76px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .security-button {
    padding: 32px 24px;
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .quotes-container {
    display: none;
  }
}

ul li .quotes-wrapper {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.quotes-quote {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.quotes-headline {
  margin-bottom: 26px;
  text-align: center;
}

.quotes-right-icon {
  transform: rotate(180deg);
  min-width: 50px;
  margin-right: 32px;
  margin-bottom: 100px;
  align-self: flex-end;
}

.quotes-left-icon {
  margin-left: 32px;
  min-width: 50px;
}

.quotes-text {
  padding: 0px 20px;
  color: #000;
  text-align: center;
}

.quotes-author-name {
  text-align: center;
  margin-top: 64px;
  font-size: 18px;
  line-height: 27px;
  color: #184962;
}

.quotes-author-title, .quotes-author-company {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #184962;
}

.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px !important;
}
.slider .slides li {
  overflow: initial;
}

li.indicator-item {
  background-color: white !important;
  border: 1px solid #00B7A8;
}

li.indicator-item.active {
  background-color: #00B7A8 !important;
}
.licensing-wrapper, .licensing-wrapper-expanded {
  padding-top: 96px;
  padding-bottom: 104px;
}

.licensing-headline {
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  padding-bottom: 48px;
  color: #184962;
}

.licensing-subheadline {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 64px;
  font-size: 24px;
  line-height: 57px;
  font-weight: 300;
  color: #184962;
}

.licensing-buttons {
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px;
  transition: 1s ease border-width;
}
@media only screen and (max-width: 600px) {
  .licensing-buttons {
    display: none;
  }
}

.licensing-button-active, .licensing-button {
  text-transform: capitalize;
  border: 2px solid #184962;
  letter-spacing: 1.5px;
  font-weight: bold;
}

.licensing-button-active {
  padding: 18px 20px;
  font-size: 14px;
  line-height: 14px;
  background-color: #184962;
  color: #FFF;
}

.licensing-button {
  padding: 15px 20px;
  font-size: 14px;
  line-height: 20px;
  background-color: #E8ECED;
  color: #184962;
}
.licensing-button:hover {
  padding: 18px 20px;
  font-size: 14px;
  line-height: 14px;
  background-color: #184962;
  color: #FFF;
}

.licensing-editions-wrapper {
  background-color: #FFF;
}
@media only screen and (max-width: 992px) {
  .licensing-editions-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0px 22px 32px 22px;
  }
}

.licensing-right-border {
  border-right: 3px solid #E8ECED;
  margin: 64px 0px;
}
.licensing-right-border:last-child {
  border-right: unset;
}
@media only screen and (max-width: 992px) {
  .licensing-right-border {
    margin: 0px 32px 32px 32px;
    border-right: unset;
  }
}

.licensing-edition {
  padding: 0px 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media only screen and (max-width: 992px) {
  .licensing-edition {
    padding: 32px 25px;
  }
}
@media only screen and (max-width: 600px) {
  .licensing-edition {
    padding: 16px 0;
  }
}

.licensing-editions-headline {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 16px;
  margin-bottom: 32px;
  color: #184962;
}
@media only screen and (max-width: 600px) {
  .licensing-editions-headline {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
    padding-top: 16px;
    padding-bottom: 4px;
  }
}

.licensing-editions-text-wrapper {
  height: 240px;
}
@media only screen and (max-width: 992px) {
  .licensing-editions-text-wrapper {
    height: unset;
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  img.licensing-icon {
    height: 60px;
  }
}

.licensing-editions-text {
  list-style-type: disc !important;
  padding: 0px 18px 16px 18px;
  color: #184962;
  text-align: left;
}
@media only screen and (max-width: 992px) {
  .licensing-editions-text {
    padding-bottom: 8px;
  }
}
@media only screen and (max-width: 600px) {
  .licensing-editions-text {
    font-size: 16px;
    line-height: 24px;
  }
}

.licensing-editions-button {
  margin-top: 32px;
}

.licensing-info-wrapper {
  display: none;
}
@media only screen and (max-width: 600px) {
  .licensing-info-wrapper {
    display: block;
    margin-top: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .licensing-info-text {
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
    color: #184962;
    text-align: center;
  }
}

.licensing-bottom-button-wrapper {
  margin-top: -54px;
  padding-bottom: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 992px) {
  .licensing-bottom-button-wrapper {
    margin-top: 32px;
    padding-bottom: 64px;
  }
}
@media only screen and (max-width: 600px) {
  .licensing-bottom-button-wrapper {
    margin: unset;
    padding: 32px 24px;
  }
}

.licensing-comparison-wrapper {
  padding-top: 96px;
  padding-bottom: 104px;
}
@media only screen and (max-width: 992px) {
  .licensing-comparison-wrapper {
    margin: 0px 32px;
  }
}
@media only screen and (max-width: 600px) {
  .licensing-comparison-wrapper {
    background-color: #FFF;
    margin: 0px 24px;
  }
}

.licensing-comparison-headline {
  cursor: pointer;
  margin-top: 0;
  padding-bottom: 64px;
  text-align: center;
  color: #184962;
}
@media only screen and (max-width: 992px) {
  .licensing-comparison-headline {
    padding: 64px 24px;
  }
}
@media only screen and (max-width: 600px) {
  .licensing-comparison-headline {
    padding-bottom: 0px;
    margin-bottom: -16px;
  }
}
.img-class {
  width: 100%;
  width: -webkit-fill-available;
}

/*.divider-shape-color-light {
  @extend .image-on-dark-bg;
}*/
/*.filter-icons-color {
  @extend .image-on-dark-bg;
}*/
/*.divider-shape-color {
  @extend .image-on-light-bg-2;
}*/
.arrows-bg {
  background-color: #B0F8F2;
}
.texttile-container {
  padding-top: 160px;
  padding-bottom: 160px;
  background: #FFF;
}
@media only screen and (max-width: 992px) {
  .texttile-container {
    padding: 64px 0px;
  }
}
@media only screen and (max-width: 600px) {
  .texttile-container {
    padding: 32px 0px 32px 0px;
  }
}

.row.texttile-row-wrapper {
  margin-bottom: 0px;
}

@media only screen and (max-width: 992px) {
  .texttile-row {
    margin: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .texttile-row {
    margin-buttom: 32px;
    width: 100%;
  }
}

.texttile-image-container {
  background-size: cover;
  width: 25vw;
  height: 25vw;
  max-width: 360px;
  max-height: 360px;
}
@media only screen and (max-width: 992px) {
  .texttile-image-container {
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 600px) {
  .texttile-image-container {
    margin-bottom: 8px;
  }
}

.texttile-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  text-align: center;
}
@media only screen and (max-width: 450px) {
  .texttile-text-container {
    justify-content: space-between;
  }
}

.texttile-headline {
  margin-bottom: 32px;
  margin-top: 0px;
}
@media only screen and (max-width: 600px) {
  .texttile-headline {
    margin-bottom: 8px;
    margin-top: 24px;
  }
}

.texttile-text {
  text-align: center;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .texttile-text {
    letter-spacing: normal;
    margin-bottom: 32px;
  }
}
::selection {
  background-color: #184962;
  color: #FFF;
}

*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

body {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #184962;
}

h1 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: "Parafina-SemiWideXBold";
  font-size: 96px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -1.5px;
}
@media only screen and (max-width: 992px) {
  h1 {
    font-size: 72px;
  }
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 60px;
  }
}

h2 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: Parafina-SemiWideXBold;
  font-size: 60px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -0.5px;
}
@media only screen and (max-width: 992px) {
  h2 {
    font-size: 56px;
  }
}
@media only screen and (max-width: 600px) {
  h2 {
    font-size: 42px;
  }
}

h3 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: Parafina-SemiWideXBold;
  font-size: 48px;
  font-weight: 900;
  line-height: 100%;
}
@media only screen and (max-width: 992px) {
  h3 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 600px) {
  h3 {
    font-size: 36px;
  }
}

h4 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: Parafina-SemiWideXBold;
  font-size: 40px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.25px;
}
@media only screen and (max-width: 992px) {
  h4 {
    font-size: 34px;
  }
}
@media only screen and (max-width: 600px) {
  h4 {
    font-size: 28px;
  }
}

h5 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: Parafina-SemiWideBold;
  font-size: 28px;
  font-weight: 700;
  line-height: 100%;
}
@media only screen and (max-width: 992px) {
  h5 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 600px) {
  h5 {
    font-size: 20px;
  }
}

p {
  margin: 0px;
}

button {
  border: none;
}

a {
  cursor: pointer;
  color: #184962;
  text-decoration: underline;
}

li a {
  text-decoration: none;
}

iframe {
  border: none;
}

.container, .row, .container .row {
  width: inherit;
  margin: 0px auto;
}

div.row {
  max-width: 1180px;
  margin-bottom: 0px;
}

.row .col {
  padding: 0px 20px;
  margin-left: 0px;
  margin-right: 0px;
}
@media only screen and (max-width: 600px) {
  .row .col {
    padding-top: 20px;
    -webkit-hyphens: none;
            hyphens: none;
  }
}

.default-padding-top-bottom {
  padding-top: 96px;
  padding-bottom: 96px;
}
@media only screen and (max-width: 992px) {
  .default-padding-top-bottom {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}
@media only screen and (max-width: 600px) {
  .default-padding-top-bottom {
    padding-top: 42px;
    padding-bottom: 42px;
  }
}

.default-margin-top-bottom {
  margin-top: 96px;
  margin-bottom: 96px;
}
@media only screen and (max-width: 992px) {
  .default-margin-top-bottom {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}
@media only screen and (max-width: 600px) {
  .default-margin-top-bottom {
    margin-top: 42px;
    margin-bottom: 42px;
  }
}

.color-schema-dark {
  color: #FFF;
  background-color: #184962;
}

.color-schema-light {
  color: #184962;
  background-color: #B0F8F2;
}

.color-schema-white {
  color: #184962;
  background-color: #FFF;
}

.color-schema-svg-dark {
  fill: #B0F8F2;
  stroke: #B0F8F2;
}

.color-schema-svg-light {
  fill: #02F0DD;
  stroke: #02F0DD;
}

.color-schema-svg-white {
  fill: #B0F8F2;
  stroke: #B0F8F2;
}

.subtitle {
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
  text-align: left;
}

.hidden {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* Override default yellow background */
  box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  /* Set text color */
  -webkit-text-fill-color: #184962 !important;
  border: 1px solid #ccc !important;
}

.body1 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.body2 {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  text-align: left;
}

.body-din {
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: center;
}

.button-medium {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: left;
}

.button-large {
  font-family: "Inter-Regular";
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-align: left;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.md-96 {
  font-size: 96px;
}

.material-icons {
  color: #00B7A8;
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
@media only screen and (max-width: 600px) {
  .glide__slides {
    grid-gap: 82.5px;
    gap: 82.5px;
  }
}

.glide__slides--dragging {
  -webkit-user-select: none;
          user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  -webkit-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.glide__slide a {
  -webkit-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.glide__arrows {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}

.glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}

.glide--rtl {
  direction: rtl;
}


.title-wrapper-style, .title-wrapper-style-heise {
  background-color: #FFF;
  padding: 64px 32px;
}
@media only screen and (max-width: 992px) {
  .title-wrapper-style, .title-wrapper-style-heise {
    padding-bottom: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .title-wrapper-style, .title-wrapper-style-heise {
    padding: 32px 24px;
  }
}
.title-wrapper-style-heise {
  background-color: #FFF;
}

.title-headline-style, .title-headline-style-flx, .title-headline-style-heise {
  color: #184962;
  text-align: center;
  padding: 40px 20px 0px 20px;
  margin-top: 40px;
}
@media only screen and (max-width: 992px) {
  .title-headline-style, .title-headline-style-flx, .title-headline-style-heise {
    padding: 0px 0px 0px 0px;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 600px) {
  .title-headline-style, .title-headline-style-flx, .title-headline-style-heise {
    padding: 0px 0px 0px 0px;
    margin-top: 15px;
  }
}
.title-headline-style-heise {
  background-color: #478EB3;
  color: white;
}

.title-headline-style-flx {
  text-align: left;
  text-transform: none;
}

.title-text-style, .title-text-style-flx, .title-text-style-heise {
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #184962;
  padding: 0px 18px;
  text-align: center;
  text-transform: none;
}
@media only screen and (max-width: 600px) {
  .title-text-style, .title-text-style-flx, .title-text-style-heise {
    font-size: 16px;
    line-height: 25px;
    padding: 0px 18px;
  }
}
.title-text-style-heise {
  background-color: #478EB3;
  color: white;
}

.title-text-style-flx {
  text-align: left;
}

.title-button-wrapper {
  margin-top: 50px;
}
@media only screen and (max-width: 600px) {
  .title-button-wrapper {
    margin-top: 20px;
  }
}

.title-breadcrumb-item {
  color: #184962;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.8px;
  padding: 0px 5px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .title-breadcrumb-item {
    letter-spacing: 0.67px;
    font-size: 11px;
    line-height: 12px;
  }
}
.contact-wrapper {
  padding: 64px 32px;
}
@media only screen and (max-width: 600px) {
  .contact-wrapper {
    padding: 32px 24px;
  }
}

.company-information-container {
  border-right: 3px solid #E8ECED;
  margin-right: 40px;
  padding-right: 32px;
}
@media only screen and (max-width: 992px) {
  .company-information-container {
    border-right: unset;
    margin-right: 0;
    padding-right: 0;
  }
}

.company-information-header {
  font-size: 24px;
  text-align: left;
  margin-bottom: 16px;
  letter-spacing: 0.8px;
}
@media only screen and (max-width: 992px) {
  .company-information-header {
    text-align: center;
  }
}
@media only screen and (max-width: 992px) {
  .company-information-header {
    line-height: 20px;
    letter-spacing: 0.67px;
  }
}

.company-information-text {
  font-size: 18px;
  text-align: left;
  line-height: 27px;
  color: #63737E;
  padding-bottom: 48px;
  word-break: break-all;
}
@media only screen and (max-width: 992px) {
  .company-information-text {
    text-align: center;
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
}

.contact-info-container {
  padding-left: 32px;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 992px) {
  .contact-info-container {
    border-top: 3px solid #E8ECED;
    border-left: none;
    padding-top: 32px;
    padding-left: 0;
  }
}

.contact-tech-wrapper, .contact-info-wrapper, .contact-message-wrapper, .contact-newsletter-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
}
.contact-tech-wrapper:last-child, .contact-info-wrapper:last-child, .contact-message-wrapper:last-child, .contact-newsletter-wrapper:last-child {
  margin-bottom: 0;
}
@media only screen and (max-width: 600px) {
  .contact-tech-wrapper, .contact-info-wrapper, .contact-message-wrapper, .contact-newsletter-wrapper {
    margin-bottom: 32px;
  }
}

.contact-information-headline {
  color: #184962;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 3.3px;
  text-transform: uppercase;
  padding-bottom: 30px;
}
@media only screen and (max-width: 600px) {
  .contact-information-headline {
    padding-bottom: 0;
  }
}

.input-field-label {
  color: #184962 !important;
}

input#first_name,
input#last_name,
input#job_title,
input#company,
input#address,
input#zipcode,
input#city,
input#email2,
input#phone_number,
input#website,
input#country,
input#elasticsearch_version,
input#current_stage,
textarea#message,
input#organization,
input#tls-email,
input#host1,
input#host2,
input#host2,
input#host3,
input#host4,
input#host5,
input#host6,
input#host7,
input#host8,
input#host9,
input#host10 {
  border-bottom: 2px solid #184962;
  box-shadow: none;
}

.privacy-policy {
  margin: 32px 0px;
}

.drop-down-container.col.s12.m6 {
  padding: 0;
}

@media only screen and (max-width: 600px) {
  #contact {
    padding: 0;
  }
}

.cf-feedback {
  display: none;
}

.cf-feedback-text {
  text-align: left;
  color: #184962;
}
.drop-down-container {
  cursor: pointer;
  border-bottom: 2px solid #184962;
  margin-top: 16px;
  margin-right: 21px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding-left: 0;
}

.drop-down-wrapper.col.s12.m6 {
  padding-left: 0;
}
@media only screen and (max-width: 600px) {
  .drop-down-wrapper.col.s12.m6 {
    padding: 0;
    margin-bottom: 16px;
  }
}

.drop-down-label {
  position: absolute;
  top: -10px;
}

.drop-down-text-field {
  font-family: Roboto;
  font-size: 15px;
  color: #184962;
  line-height: 22px;
  padding: 0px;
  margin: 12px 0;
}
@media only screen and (max-width: 992px) {
  .drop-down-text-field {
    line-height: 15px;
  }
}

.drop-down-open-and-close-button {
  width: 10px;
  transition-duration: 0.3s;
  position: absolute;
  right: 1px;
}

.drop-down-close-button {
  transform: rotate(180deg);
}

.drop-down-options-container {
  position: absolute;
  top: 47px;
  left: 0px;
  color: #184962;
  width: 100%;
  background-color: white;
  z-index: 1;
}

select.browser-default {
  border: unset;
  border-bottom: 2px solid #184962;
  color: #184962;
  padding: 10px;
}
select.browser-default:hover {
  background-color: #184962;
  color: white;
}

.blogpost-wrapper {
  display: flex;
  flex: 1 1 450px;
  flex-direction: column;
  margin: 32px 16px;
  padding-bottom: 32px;
  background-color: #E8ECED;
  max-width: 500px;
  text-decoration: none;
}
@media only screen and (max-width: 992px) {
  .blogpost-wrapper {
    margin: 0px;
    max-width: 100%;
  }
}

.blogpost-image-wrapper {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  transition: 0.3s ease-in-out;
}
.blogpost-image-wrapper:hover {
  filter: grayscale(0);
}

img.blogpost-feed-image {
  margin: auto;
  max-height: auto;
  max-width: 100%;
  object-fit: cover;
  transition: 0.3s;
}
img.blogpost-feed-image:hover {
  transition: 0.3s;
  transform: scale(1.05);
}
@media only screen and (max-width: 992px) {
  img.blogpost-feed-image {
    width: 100%;
    max-height: auto;
  }
}

.blogpost-headline {
  padding: 32px 32px 0 32px;
  color: #184962;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .blogpost-headline {
    font-size: 16px;
    line-height: 24px;
  }
}

.blogpost-info-headline {
  padding: 0 32px;
  color: #184962;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 3.3px;
}

.blogpost-paragraph {
  color: #184962;
  padding: 0 32px;
  margin-bottom: 36px;
  font-size: 18px;
  line-height: 27px;
}
@media only screen and (max-width: 600px) {
  .blogpost-paragraph {
    font-size: 16px;
    line-height: 24px;
  }
}

.blogpost-info-link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #02F0DD;
  padding-right: 32px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 15px;
  font-weight: 700;
  margin-top: auto;
}

.blogpost-arrow {
  margin-left: 8px;
}
.searchblogpost-wrapper {
  display: flex;
  flex-direction: column;
}

.searchblogpost-item {
  display: flex;
  flex-direction: column;
  margin: 0px 20px 64px 20px;
}
@media only screen and (max-width: 600px) {
  .searchblogpost-item {
    margin: 16px 4px;
  }
}

.searchblogpost-title,
.searchblogpost-result-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-bottom: 8px;
}
@media only screen and (max-width: 600px) {
  .searchblogpost-title,
  .searchblogpost-result-headline {
    font-size: 20px;
    letter-spacing: 0.67px;
  }
}

.searchblogpost-result-headline {
  padding: 0px 20px 32px 20px;
  font-weight: bold;
  text-align: center;
}

.searchblogpost-author {
  color: #184962;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 3.3px;
  margin-bottom: 8px;
}

.searchblogpost-content {
  color: #184962;
  font-size: 18px;
  line-height: 27px;
}

.searchblogpost-no-results {
  text-align: center;
  margin: 64px;
  color: #184962;
  font-size: 48px;
  line-height: 57px;
}
@media only screen and (max-width: 600px) {
  .searchblogpost-no-results {
    font-size: 27px;
    line-height: 32px;
  }
}
.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin: 128px 32px;
}

li.pagination-number a {
  padding: 14px 18px;
  font-size: 18px;
  color: #02F0DD;
  font-weight: bold;
  cursor: pointer;
}
li.pagination-number a:focus {
  border-bottom: 2px solid #00B7A8;
}
.blog-wrapper {
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.blog-searchbar {
  margin: 32px;
}

.blog-back {
  text-align: center;
  letter-spacing: 1.5px;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  display: flex;
  font-weight: 700;
  justify-content: center;
  text-align: center;
  margin: 32px 0;
}

.blog-arrow-back {
  margin-right: 8px;
}
.blogtitle-container, .blogtitle-subtext-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFF;
}

.blogtitle-subtext-container {
  padding-bottom: 32px;
}
@media only screen and (max-width: 992px) {
  .blogtitle-subtext-container {
    padding: 32px 32px 0px 32px;
    background-color: #FFF;
    color: #184962;
  }
}
@media only screen and (max-width: 600px) {
  .blogtitle-subtext-container {
    padding: 16px 24px 0px 24px;
  }
}

.blogtitle-wrapper {
  margin-top: 50px;
  max-width: 1180px;
  padding: 32px 20px 0px 20px;
}
@media only screen and (max-width: 992px) {
  .blogtitle-wrapper {
    margin: 24px 32px 48px 32px;
  }
}
@media only screen and (max-width: 600px) {
  .blogtitle-wrapper {
    margin: 50px 24px;
    padding: 0;
  }
}

.blogtitle-text {
  text-transform: uppercase;
  text-align: center;
  max-width: 1180px;
  color: #184962;
  margin-bottom: 32px;
  margin-top: 32px;
}
@media only screen and (max-width: 600px) {
  .blogtitle-text {
    margin-bottom: 0px;
  }
}

.blogtitle-sub-text {
  display: flex;
  justify-content: space-evenly;
  text-transform: uppercase;
  margin: 0px;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 3.3px;
  color: #184962;
}
@media only screen and (max-width: 992px) {
  .blogtitle-sub-text {
    color: #184962;
  }
}

.blogtitle-tag {
  padding: 0 4px;
  color: #184962;
  transition: all 0.2s;
}
.blogtitle-tag:not(:last-child)::after {
  content: ", ";
}
.blogtitle-tag:hover {
  color: #478EB3;
}
@media only screen and (max-width: 992px) {
  .blogtitle-tag {
    color: #184962;
  }
}
.blog-box__wrapper {
  padding: 128px 0;
}
@media only screen and (max-width: 992px) {
  .blog-box__wrapper {
    padding: 64px 0;
  }
}
@media only screen and (max-width: 600px) {
  .blog-box__wrapper {
    padding: 0;
  }
}
.blog-box__postsyoulike {
  padding: 0px 0;
}
@media only screen and (max-width: 992px) {
  .blog-box__postsyoulike {
    padding: 0px 0;
  }
}
@media only screen and (max-width: 600px) {
  .blog-box__postsyoulike {
    padding: 0;
  }
}
.blog-box__headline, .blog-box__headline-overview {
  color: #184962;
  text-align: center;
  padding: 64px 0;
  font-weight: 300;
}
@media only screen and (max-width: 600px) {
  .blog-box__headline, .blog-box__headline-overview {
    padding-top: 64px;
    padding-bottom: 12px;
  }
}
.blog-box__headline {
  font-size: 24px;
  line-height: 30px;
}
.blog-box__headline-overview {
  font-size: 48px;
  line-height: 57px;
}
.blog-box__container {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.blog-box__box {
  padding-bottom: 32px;
  background-color: #E8ECED;
  display: flex;
  flex: 1 1 300px;
  flex-direction: column;
  max-width: 500px;
  margin-bottom: 12px;
  text-decoration: none;
  color: #184962;
  line-height: 27px;
  font-size: 18px;
  cursor: pointer;
}
.blog-box__box:nth-child(2) {
  margin: 0 112px 12px;
}
@media only screen and (max-width: 992px) {
  .blog-box__box {
    max-width: 100%;
    margin-bottom: 12px;
  }
  .blog-box__box:nth-child(2) {
    margin: 0;
  }
}
.blog-box__box-content-headline {
  font-weight: bold;
  padding: 32px 32px 0 32px;
}
.blog-box__box-content-info {
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 3.3px;
  padding: 0 32px;
}
.blog-box__box-content-text {
  margin-bottom: 24px;
  padding: 0 32px;
}
.blog-box-info-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 32px;
  margin-top: auto;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 15px;
  font-weight: 700;
}
.blog-box__box-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.blog-box__box-image-container:hover {
  filter: grayscale(0);
}
.blog-box-button {
  margin-top: 64px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .blog-box-button {
    margin: 32px 24px;
  }
}

img.blog-box__box-image {
  max-height: auto;
  max-width: 100%;
  object-fit: cover;
  object-position: top;
  display: block;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
img.blog-box__box-image:hover {
  transform: scale(1.05);
}
@media only screen and (max-width: 992px) {
  img.blog-box__box-image {
    width: 100%;
    max-height: auto;
  }
}
.code-highlight {
  position: relative;
}

.code-highlight .copy-code {
  font-size: 18px;
  cursor: pointer;
  top: 0;
  right: 0;
  font-weight: 400;
  position: absolute;
  border: 1px solid #dae0e7;
  padding: 9px 10px;
  border-radius: 0 3px;
  color: black;
  background-color: #f6f7f8;
  transition: color 0.25s ease;
}

.code-highlight .copy-code:before {
  content: "\E14D";
  font-family: Material Icons;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 4px;
}
@charset "UTF-8";
.flex-center {
  justify-content: center;
  justify-items: center;
  display: flex;
}

.row.blogpostarticle-wrapper {
  padding: 0 12px;
  text-wrap: pretty;
  word-break: break-word;
}

.row.blogpostarticle-author {
  margin-top: 32px;
  font-size: 18px;
  font-weight: 300;
}

.blogpostarticle-avatar {
  border-radius: 50%;
}

.blogpostarticle-headline1 {
  color: #184962;
  margin-top: 64px;
  margin-bottom: 32px;
  font-size: 48px;
  font-weight: 300;
  line-height: 57px;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-headline1 {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .blogpostarticle-headline1 {
    font-size: 24px;
    margin-top: 32px;
  }
}

.blogpostarticle-headline2 {
  margin-top: 48px;
  margin-bottom: 32px;
  color: #184962;
  font-size: 32px;
  letter-spacing: 0.8px;
  line-height: 30px;
  text-align: left;
}

.blogpostarticle-headline3 {
  margin-top: 32px;
  margin-bottom: 16px;
  color: #184962;
  font-size: 24px;
  letter-spacing: 0.8px;
  line-height: 30px;
}

.blogpostarticle-code-snippet {
  background-color: #E8ECED;
  font-family: Courier;
  font-size: 16px;
  line-height: 19px;
  padding: 16px;
  word-break: break-all;
  overflow: scroll;
  margin-top: 24px;
  margin-bottom: 24px;
}

.blogpostarticle-quote-wrapper {
  margin: 26px 30px 30px -15vw;
  max-width: 425px;
  float: left;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-quote-wrapper {
    margin-left: 0px;
    float: none;
    max-width: unset;
  }
}
@media only screen and (max-width: 600px) {
  .blogpostarticle-quote-wrapper {
    margin-right: 0px;
  }
}

.blogpostarticle-quote-image {
  float: left;
}
@media only screen and (max-width: 600px) {
  .blogpostarticle-quote-image {
    width: 26px;
    height: 29px;
  }
}

.blogpostarticle-quote-text .blogpostarticle-text {
  margin: 0px;
  max-width: 360px;
  margin-left: 70px;
  color: #184962;
  padding: 16px 0px;
  font-size: 24px;
  letter-spacing: 0.8px;
  line-height: 27px;
  border-top: 10px solid #E8ECED;
  border-bottom: 10px solid #E8ECED;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-quote-text .blogpostarticle-text {
    max-width: 85%;
  }
}
@media only screen and (max-width: 600px) {
  .blogpostarticle-quote-text .blogpostarticle-text {
    font-size: 18px;
    line-height: 20px;
    max-width: 100%;
    margin-left: 40px;
  }
}

.blogpostarticle-image-wrapper.blogpostarticle-image {
  display: block;
  margin: auto;
  width: auto;
  height: auto;
  max-height: 500px;
  max-width: 100%;
  margin-top: 30px;
}

.blogpostarticle-image-description {
  color: #184962;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.blogpostarticle-text {
  margin-bottom: 32px;
  margin-top: 32px;
  color: #184962;
  font-size: 18px;
  line-height: 27px;
}
@media only screen and (max-width: 600px) {
  .blogpostarticle-text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.blogpostarticle-sidebar-container {
  margin: 64px 0px;
  border-top: 6px solid #E8ECED;
  border-bottom: 6px solid #E8ECED;
  max-width: 68px;
  position: sticky;
  top: 64px;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-sidebar-container {
    position: unset;
    max-width: 100%;
  }
}

.blogpostarticle-sidebar-icons-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-sidebar-icons-container {
    flex-direction: row;
    border-bottom: 6px solid #E8ECED;
    border: unset;
  }
}

.blogpostarticle-sidebar-title {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  max-width: 68px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 3.3px;
  color: #184962;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-sidebar-title {
    display: none;
  }
}

.blogpostarticle-sidebar-icon {
  margin: 8px 16px;
  cursor: pointer;
}
.blogpostarticle-sidebar-icon:last-child {
  margin-bottom: 16px;
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-sidebar-icon:last-child {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 992px) {
  .blogpostarticle-sidebar-icon {
    display: flex;
  }
}
.blogpostarticle-sidebar-icon img:hover, .blogpostarticle-sidebar-icon img:focus, .blogpostarticle-sidebar-icon img:active {
  opacity: 0.8;
}

.blogpostarticle-share-icon {
  width: 32px;
  height: 32px;
  fill: #184962;
}

.blogpostarticle-recommended-headline {
  margin: 124px 0px 64px 0px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  text-align: center;
  color: #184962;
}

.bold {
  font-weight: bold;
  display: inline;
}

.underline {
  text-decoration: underline;
  display: inline;
}

.italic {
  font-style: italic;
  display: inline;
}

.blogpostarticle-listitem {
  color: #184962;
  font-size: 18px;
  line-height: 27px;
}
@media only screen and (max-width: 600px) {
  .blogpostarticle-listitem {
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.blogpostarticle-listitem:before {
  content: "\2022";
  margin-left: -20px;
  padding-right: 13px;
}
.blog-categories-wrapper {
  padding: 0 2.4rem;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.blog-categories-title {
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 3.3px;
  color: #184962;
  text-transform: uppercase;
}
.blog-categories-items-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.blog-categories-item {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  color: #184962;
  border: 1px solid #184962;
  border-radius: 3px;
  margin-bottom: 10px;
  transition: all 0.2s;
}
.blog-categories-item:hover {
  color: #FFF;
  background-color: #184962;
}
.blog-categories-item:not(:last-child) {
  margin-right: 16px;
}
.faq-wrapper {
  padding: 128px 0px;
}
@media only screen and (max-width: 992px) {
  .faq-wrapper {
    padding: 64px 32px;
  }
}
@media only screen and (max-width: 600px) {
  .faq-wrapper {
    padding: 64px 24px 32px 24px;
  }
}

.faq-headline {
  text-align: center;
  font-size: 48px;
  line-height: 57px;
  font-weight: 300;
  color: #184962;
  padding-bottom: 55px;
}
@media only screen and (max-width: 992px) {
  .faq-headline {
    font-size: 36px;
    line-height: 43px;
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .faq-headline {
    font-size: 24px;
    line-height: 28px;
  }
}

.faq-info-boxes {
  display: flex;
  justify-content: space-evenly;
  margin: 0px 18px 64px 18px;
}
@media only screen and (max-width: 992px) {
  .faq-info-boxes {
    display: inline;
    margin-bottom: 32px;
  }
}

.faq-info-box {
  border: 3px solid #E8ECED;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 360px;
  padding: 32px;
  margin: 0px 15px;
}
.faq-info-box:hover {
  border-color: #02F0DD;
}
@media only screen and (max-width: 992px) {
  .faq-info-box {
    margin: 32px 0px;
    width: 100%;
    height: 280px;
  }
}
@media only screen and (max-width: 600px) {
  .faq-info-box {
    padding: 16px;
  }
}

.faq-info-box-headline, .faq-info-box-text {
  font-size: 18px;
  line-height: 27px;
  color: #63737E;
}
@media only screen and (max-width: 600px) {
  .faq-info-box-headline, .faq-info-box-text {
    font-size: 16px;
    line-height: 24px;
  }
}

.faq-info-box-headline {
  font-weight: bold;
  margin-bottom: 27px;
}
@media only screen and (max-width: 992px) {
  .faq-info-box-headline {
    margin-bottom: 27px;
  }
}

.faq-button {
  display: flex;
  justify-content: center;
}
.video-wrapper {
  padding-top: 128px;
  background: linear-gradient(180deg, #E8ECED 30%, #FFF 30%);
}
@media only screen and (max-width: 992px) {
  .video-wrapper {
    padding: 64px 32px;
  }
}
@media only screen and (max-width: 600px) {
  .video-wrapper {
    padding: 64px 24px 32px 24px;
  }
}

.video-wrapper-long {
  padding-bottom: 128px;
}

.video-headline {
  text-align: center;
  font-size: 48px;
  line-height: 57px;
  font-weight: 300;
  padding-bottom: 64px;
  color: #184962;
}
@media only screen and (max-width: 992px) {
  .video-headline {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .video-headline {
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 32px;
  }
}

.video-description-video {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.video-iframe-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.video-description {
  margin: 0px 32px 32px 32px;
}
@media only screen and (max-width: 992px) {
  .video-description {
    margin: 0px;
  }
}

.video-description-headline {
  margin-top: 128px;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 30px;
  color: #184962;
  letter-spacing: 0.8px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .video-description-headline {
    font-size: 20px;
    line-height: 30px;
    margin-top: 16px;
    margin-bottom: 8px;
    letter-spacing: 0.67px;
  }
}

.video-description-text {
  font-size: 18px;
  line-height: 27px;
  color: #63737E;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .video-description-text {
    font-size: 16px;
    line-height: 24px;
  }
}

.video-playlist-headline {
  text-align: center;
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-bottom: 64px;
}
@media only screen and (max-width: 992px) {
  .video-playlist-headline {
    display: none;
  }
}

.video-playlist-wrapper {
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.video-playlist-item {
  margin-bottom: 32px;
}

.video-playlist-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  margin-top: 16px;
}

.video-button {
  display: flex;
  justify-content: center;
  margin-top: 64px;
}
@media only screen and (max-width: 992px) {
  .video-button {
    margin-top: 32px;
  }
}

.ytp-large-play-button-bg {
  background: green !important;
}
.journey-wrapper {
  padding: 96px 30px 96px 104px;
}
@media only screen and (max-width: 600px) {
  .journey-wrapper {
    display: none;
  }
}

.journey-headline {
  padding: 0px 20px 144px 0px;
  text-align: center;
  color: #184962;
}
@media only screen and (max-width: 992px) {
  .journey-headline {
    padding: 96px 20px 64px 20px;
  }
}

.timeline {
  color: #184962;
}
.timeline ul li {
  background: #02F0DD;
  position: relative;
  margin: 0 auto;
  width: 5px;
  padding-bottom: 40px;
  list-style-type: none;
}
.timeline ul li:last-child {
  padding-bottom: 7px;
}
.timeline ul li:before {
  content: "";
  background: #FFF;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 20px;
  height: 20px;
  border: 3px solid #02F0DD;
  border-radius: 50%;
}
.timeline ul li .content {
  position: relative;
  top: 7px;
  width: 450px;
  padding: 20px;
  transition: opacity 500ms;
}
.timeline ul li .content h2 {
  margin-top: -45px;
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 48px;
  line-height: 57px;
}
@media only screen and (max-width: 600px) {
  .timeline ul li .content h2 {
    font-size: 36px;
    line-height: 43px;
  }
}
.timeline ul li .content p {
  background: #FFF;
  padding: 16px;
}
.timeline ul li .content p #headline {
  color: #184962;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 3.3px;
  margin-bottom: 8px;
}
@media only screen and (max-width: 600px) {
  .timeline ul li .content p #headline {
    margin-bottom: 8px;
  }
}
.timeline ul li .content p #text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 32px;
}
.timeline ul li .content p #highlight {
  color: #63737E;
  background-color: #02F0DD;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  padding: 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 32px;
}
.timeline ul li .content:before {
  content: "";
  background: #02F0DD;
  position: absolute;
  top: 0px;
  width: 38px;
  height: 5px;
}
.timeline ul li:nth-child(odd) .content {
  left: 50px;
}
.timeline ul li:nth-child(odd) .content h2 {
  text-align: left;
}
.timeline ul li:nth-child(odd) .content:before {
  left: -38px;
}
.timeline ul li:nth-child(even) .content {
  left: calc(-450px - 45px);
}
.timeline ul li:nth-child(even) .content h2 {
  text-align: right;
}
.timeline ul li:nth-child(even) .content:before {
  right: -38px;
}

@media screen and (max-width: 992px) {
  .timeline ul li .content {
    width: 41vw;
  }
  .timeline ul li:nth-child(even) .content {
    left: calc(-41vw - 45px);
  }
}
@media screen and (max-width: 768px) {
  .timeline ul li {
    margin-left: 20px;
  }
  .timeline ul li .content {
    width: 80vw;
  }
  .timeline ul li:nth-child(even) .content {
    left: 45px;
  }
  .timeline ul li:nth-child(even) .content h2 {
    text-align: left;
  }
  .timeline ul li:nth-child(even) .content:before {
    left: -33px;
  }
}
.collapse-header {
  cursor: pointer;
}

.details {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  border-radius: 15px;
}

.details.fadein {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

.updown:nth-child(odd) {
  left: -38px;
}
.updown:nth-child(even) {
  left: 38px;
}
.company-partners-wrapper {
  background-color: #FFF;
  padding-top: 96px;
  padding-bottom: 104px;
}
@media only screen and (max-width: 992px) {
  .company-partners-wrapper {
    padding: 64px 32px;
  }
}
@media only screen and (max-width: 600px) {
  .company-partners-wrapper {
    padding: 32px 24px 16px 24px;
  }
}

.company-partners-headline {
  margin-bottom: 64px;
  color: #184962;
  font-size: 48px;
  line-height: 57px;
  text-align: center;
  font-weight: 300;
}
@media only screen and (max-width: 992px) {
  .company-partners-headline {
    margin-bottom: 32px;
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .company-partners-headline {
    font-size: 24px;
    line-height: 28px;
  }
}

.company-partners-pic-wrapper {
  text-align: center;
}

.company-partners-pic img,
.company-partners-pic-mobile img {
  height: auto;
  max-width: 100%;
}
@media only screen and (max-width: 600px) {
  .company-partners-pic img,
  .company-partners-pic-mobile img {
    background-color: #FFF;
    width: 173px;
    height: 80px;
    margin-bottom: 16px;
  }
}

.company-partners-pic-mobile {
  text-align: center;
}
.company-team-wrapper {
  background-color: #184962;
  padding-top: 96px;
  padding-bottom: 104px;
}
@media only screen and (max-width: 992px) {
  .company-team-wrapper {
    padding: 64px 32px;
  }
}
@media only screen and (max-width: 600px) {
  .company-team-wrapper {
    padding: 32px 24px;
  }
}

.company-team-headline {
  margin-bottom: 10px;
  text-align: center;
  color: #FFF;
}
@media only screen and (max-width: 992px) {
  .company-team-headline {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .company-team-headline {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 0px;
  }
}

.company-team-profile {
  margin-top: 54px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media only screen and (max-width: 600px) {
  .company-team-profile {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

img.profile-pic {
  max-width: 160px;
  max-height: 160px;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  img.profile-pic {
    height: 121px;
    width: auto;
  }
}

img.profile-linkedIn:hover, img.profile-linkedIn:focus, img.profile-linkedIn:active {
  opacity: 0.8;
}

.profile-name {
  margin-top: 32px;
  text-align: center;
  color: #FFF;
}
@media only screen and (max-width: 992px) {
  .profile-name {
    margin-top: 8px;
  }
}
@media only screen and (max-width: 600px) {
  .profile-name {
    margin-top: 16px;
  }
}

.profile-position {
  margin-top: 8px;
  text-align: center;
  color: #FFF;
}
@media only screen and (max-width: 992px) {
  .profile-position {
    margin-top: 0px;
  }
}

.profile-link {
  text-align: center;
  margin-top: 16px;
}
@media only screen and (max-width: 600px) {
  .profile-link {
    margin-top: 8px;
  }
}
.certified-wrapper {
  padding: 128px 0px;
  background-color: #B0F8F2;
}
@media only screen and (max-width: 992px) {
  .certified-wrapper {
    padding: 64px 0px;
  }
}

.certified-row-wrapper {
  padding-bottom: 72px;
}
@media only screen and (max-width: 600px) {
  .certified-row-wrapper {
    padding-bottom: 0px;
  }
}

.certified-headline {
  margin-bottom: 64px;
  text-align: center;
  color: #184962;
}
@media only screen and (max-width: 600px) {
  .certified-headline {
    margin-bottom: 32px;
  }
}

.certified-col-img {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
}
@media only screen and (max-width: 992px) {
  .certified-col-img {
    height: unset;
    width: unset;
  }
}

.certified-col-headline {
  margin-top: 32px;
  margin-bottom: 16px;
  text-align: center;
  color: #184962;
}
@media only screen and (max-width: 992px) {
  .certified-col-headline {
    margin-top: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .certified-col-headline {
    margin-bottom: 8px;
  }
}

.certified-col-text {
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .certified-col-text {
    margin: 0px 16px;
  }
}
@media only screen and (max-width: 600px) {
  .certified-col-text {
    margin-bottom: 64px;
    margin-left: auto;
    margin-right: auto;
  }
}
.company-integrators-wrapper {
  padding-top: 96px;
  padding-bottom: 104px;
}

.company-integrators-headline {
  margin-bottom: 64px;
  color: #184962;
  font-size: 48px;
  line-height: 57px;
  text-align: center;
  font-weight: 300;
}
@media only screen and (max-width: 992px) {
  .company-integrators-headline {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .company-integrators-headline {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 28px;
  }
}

.company-integrators-section {
  border-bottom: 2px solid;
  margin-bottom: 64px;
  display: flex;
  justify-content: space-around;
  max-width: 945px;
}
.company-integrators-section:last-child {
  border-bottom: unset;
}
@media only screen and (max-width: 600px) {
  .company-integrators-section {
    border-bottom: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .company-integrators-section:last-child {
    margin-bottom: 0px;
  }
}

.integrators-pic img {
  margin-right: 32px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .integrators-pic img {
    margin-right: 0px;
  }
}

.integrators-content-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.8px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
  .integrators-content-headline {
    margin: 16px 0px;
  }
}

.integrators-content-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .integrators-content-text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

.integrators-content-button {
  display: flex;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .integrators-content-button {
    margin-bottom: 16px;
  }
}
.alerting-wrapper {
  background-color: #B0F8F2;
  padding-top: 96px;
  padding-bottom: 104px;
}

.row.alerting-row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
@media only screen and (max-width: 600px) {
  .row.alerting-row {
    flex-direction: column;
  }
}

.alerting-headline {
  margin-bottom: 32px;
  color: #184962;
  text-align: center;
}

.alerting-section {
  margin-bottom: 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  max-width: 945px;
  width: 100%;
}
.alerting-section:last-child {
  border-bottom: unset;
}
@media only screen and (max-width: 600px) {
  .alerting-section {
    border-bottom: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .alerting-section:last-child {
    margin-bottom: 0px;
  }
}

.alerting-pic img {
  margin-right: 32px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .alerting-pic img {
    margin-right: 0px;
  }
}

.alerting-content {
  text-align: center;
}

.alering-col-headline {
  margin-bottom: 32px;
  text-align: center;
  color: #FFF;
}
@media only screen and (max-width: 992px) {
  .alering-col-headline {
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .alering-col-headline {
    margin-bottom: 32px;
  }
}

.alerting-connectors-row-wrapper {
  padding-bottom: 72px;
}
@media only screen and (max-width: 600px) {
  .alerting-connectors-row-wrapper {
    padding-bottom: 0px;
  }
}

.alerting-col-text {
  text-align: center;
  color: #FFF;
}
@media only screen and (max-width: 992px) {
  .alerting-col-text {
    margin: 0px 16px;
  }
}
@media only screen and (max-width: 600px) {
  .alerting-col-text {
    margin-bottom: 64px;
    margin-left: auto;
    margin-right: auto;
  }
}

.alerting-col-img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 140px;
}
@media only screen and (max-width: 992px) {
  .alerting-col-img {
    background-color: unset;
    height: unset;
    width: unset;
  }
}

.alerting-content-headline {
  color: #184962;
  text-align: center;
  margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
  .alerting-content-headline {
    margin: 16px 0px;
  }
}

.alerting-content-text {
  text-align: center;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .alerting-content-text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

.alerting-content-button {
  display: flex;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .alerting-content-button {
    margin-bottom: 16px;
  }
}

.alerting-pre {
  background-color: #E8ECED;
  white-space: pre-wrap;
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: Courier;
  font-size: 16px;
  line-height: 19px;
  padding: 16px;
  word-break: break-all;
  overflow: scroll;
}

.alerting-connectors-wrapper {
  background-color: #184962;
}

.alerting-escalation-wrapper {
  background-color: #FFF;
}

.alerting-connectors-headline {
  margin-bottom: 64px;
  text-align: center;
  color: #FFF;
}
@media only screen and (max-width: 600px) {
  .alerting-connectors-headline {
    margin-bottom: 32px;
  }
}

.alerting-icon {
  color: #FFF;
}

.video-margin-bottom {
  margin-bottom: 50px;
}
.alerting-wrapper {
  background-color: #B0F8F2;
  padding-top: 96px;
  padding-bottom: 104px;
}

.row.alerting-row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
@media only screen and (max-width: 600px) {
  .row.alerting-row {
    flex-direction: column;
  }
}

.alerting-headline {
  margin-bottom: 32px;
  color: #184962;
  text-align: center;
}

.alerting-section {
  margin-bottom: 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  max-width: 945px;
  width: 100%;
}
.alerting-section:last-child {
  border-bottom: unset;
}
@media only screen and (max-width: 600px) {
  .alerting-section {
    border-bottom: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .alerting-section:last-child {
    margin-bottom: 0px;
  }
}

.alerting-pic img {
  margin-right: 32px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .alerting-pic img {
    margin-right: 0px;
  }
}

.alerting-content {
  text-align: center;
}

.alering-col-headline {
  margin-bottom: 32px;
  text-align: center;
  color: #FFF;
}
@media only screen and (max-width: 992px) {
  .alering-col-headline {
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .alering-col-headline {
    margin-bottom: 32px;
  }
}

.alerting-connectors-row-wrapper {
  padding-bottom: 72px;
}
@media only screen and (max-width: 600px) {
  .alerting-connectors-row-wrapper {
    padding-bottom: 0px;
  }
}

.alerting-col-text {
  text-align: center;
  color: #FFF;
}
@media only screen and (max-width: 992px) {
  .alerting-col-text {
    margin: 0px 16px;
  }
}
@media only screen and (max-width: 600px) {
  .alerting-col-text {
    margin-bottom: 64px;
    margin-left: auto;
    margin-right: auto;
  }
}

.alerting-col-img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 140px;
}
@media only screen and (max-width: 992px) {
  .alerting-col-img {
    background-color: unset;
    height: unset;
    width: unset;
  }
}

.alerting-content-headline {
  color: #184962;
  text-align: center;
  margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
  .alerting-content-headline {
    margin: 16px 0px;
  }
}

.alerting-content-text {
  text-align: center;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .alerting-content-text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

.alerting-content-button {
  display: flex;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .alerting-content-button {
    margin-bottom: 16px;
  }
}

.alerting-pre {
  background-color: #E8ECED;
  white-space: pre-wrap;
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: Courier;
  font-size: 16px;
  line-height: 19px;
  padding: 16px;
  word-break: break-all;
  overflow: scroll;
}

.alerting-connectors-wrapper {
  background-color: #184962;
}

.alerting-escalation-wrapper {
  background-color: #FFF;
}

.alerting-connectors-headline {
  margin-bottom: 64px;
  text-align: center;
  color: #FFF;
}
@media only screen and (max-width: 600px) {
  .alerting-connectors-headline {
    margin-bottom: 32px;
  }
}

.alerting-icon {
  color: #FFF;
}
.aim-section {
  margin-bottom: 12px;
  margin-top: 12px;
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  width: 100%;
}
.aim-section:last-child {
  border-bottom: unset;
}
@media only screen and (max-width: 600px) {
  .aim-section {
    border-bottom: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .aim-section:last-child {
    margin-bottom: 0px;
  }
}
@charset "UTF-8";
.faq-row-wrapper {
  padding: 64px 32px;
}
@media only screen and (max-width: 600px) {
  .faq-row-wrapper {
    padding: 32px 24px;
  }
}

.faq-question-section {
  margin-bottom: 48px;
  max-width: 75%;
}
@media only screen and (max-width: 992px) {
  .faq-question-section:last-child {
    margin-bottom: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .faq-question-section {
    margin-bottom: 32px;
    max-width: 100%;
  }
}

.faq-question-headline, .faq-answer-section-headline {
  color: #184962;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 3.3px;
}

@media only screen and (max-width: 992px) {
  .faq-answer-section-headline {
    padding-top: 64px;
  }
  .faq-answer-section-headline:first-child {
    padding-top: 32px;
  }
}

.faq-question-text {
  font-size: 18px;
  padding-bottom: 16px;
  line-height: 27px;
}
@media only screen and (max-width: 992px) {
  .faq-question-text {
    font-size: 16px;
    line-height: 24px;
  }
}
.faq-question-text a {
  color: #184962;
}

.faq-answer-section {
  border-left: 3px solid #E8ECED;
  padding-left: 100px;
}
@media only screen and (max-width: 992px) {
  .faq-answer-section {
    margin-bottom: 64px;
    border-left: unset;
    padding-left: 0px;
  }
  .faq-answer-section:first-child {
    border-top: 3px solid #E8ECED;
  }
}
@media only screen and (max-width: 992px) {
  .faq-answer-section:last-child {
    margin-bottom: 0px;
  }
}

.faq-answer-headline {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 30px;
  color: #184962;
  letter-spacing: 0.8px;
}
@media only screen and (max-width: 600px) {
  .faq-answer-headline {
    font-size: 20px;
    letter-spacing: 0.67px;
    margin-bottom: 16px;
  }
}

.faq-answer-text {
  font-size: 18px;
  line-height: 27px;
  color: #63737E;
  padding-bottom: 96px;
}
@media only screen and (max-width: 992px) {
  .faq-answer-text {
    padding-bottom: 64px;
  }
}
@media only screen and (max-width: 600px) {
  .faq-answer-text {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 32px;
  }
}
.faq-answer-text ul li:before {
  content: "\2022";
  margin-right: 20px;
  margin-left: -10px;
}

.faq-answer-links {
  word-break: break-all;
}
.free-trial-wrapper {
  padding-top: 64px;
}
@media only screen and (max-width: 992px) {
  .free-trial-wrapper {
    padding: 64px 32px 0px 32px;
  }
}
@media only screen and (max-width: 600px) {
  .free-trial-wrapper {
    padding: 32px 24px 16px 24px;
  }
}

.row.free-trial {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.free-trial-headline {
  margin-bottom: 32px;
  color: #184962;
  font-size: 48px;
  line-height: 57px;
  text-align: center;
  font-weight: 300;
}
@media only screen and (max-width: 992px) {
  .free-trial-headline {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .free-trial-headline {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 28px;
  }
}

.free-trial-section {
  border-bottom: 2px solid;
  margin-bottom: 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  max-width: 945px;
  width: 100%;
}
.free-trial-section:last-child {
  border-bottom: unset;
}
@media only screen and (max-width: 600px) {
  .free-trial-section {
    border-bottom: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .free-trial-section:last-child {
    margin-bottom: 0px;
  }
}

.free-trial-pic img {
  margin-right: 32px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .free-trial-pic img {
    margin-right: 0px;
  }
}

.free-trial-content {
  width: 80%;
}

.free-trial-content-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.8px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
  .free-trial-content-headline {
    margin: 16px 0px;
  }
}

.free-trial-content-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .free-trial-content-text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

.free-trial-content-button {
  display: flex;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .free-trial-content-button {
    margin-bottom: 16px;
  }
}

.free-trial-pre {
  background-color: #E8ECED;
  white-space: pre-wrap;
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: Courier;
  font-size: 16px;
  line-height: 19px;
  padding: 16px;
  word-break: break-all;
  overflow: scroll;
}
.compliance-wrapper {
  padding-top: 64px;
}
@media only screen and (max-width: 992px) {
  .compliance-wrapper {
    padding: 64px 32px 0px 32px;
  }
}
@media only screen and (max-width: 600px) {
  .compliance-wrapper {
    padding: 32px 24px 16px 24px;
  }
}

.row.compliance {
  max-width: 960px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.compliance-headline {
  margin-bottom: 32px;
  color: #184962;
  font-size: 48px;
  line-height: 57px;
  text-align: center;
  font-weight: 300;
}
@media only screen and (max-width: 992px) {
  .compliance-headline {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .compliance-headline {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 28px;
  }
}

.compliance-headline-form {
  margin-top: 32px;
  color: #184962;
  font-size: 48px;
  line-height: 57px;
  text-align: center;
  font-weight: 300;
}
@media only screen and (max-width: 992px) {
  .compliance-headline-form {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .compliance-headline-form {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 28px;
  }
}

.compliance-section {
  margin-bottom: 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  max-width: 945px;
  width: 100%;
}
.compliance-section:last-child {
  border-bottom: unset;
}
@media only screen and (max-width: 600px) {
  .compliance-section {
    border-bottom: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .compliance-section:last-child {
    margin-bottom: 0px;
  }
}

.compliance-pic img {
  margin-right: 32px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .compliance-pic img {
    margin-right: 0px;
  }
}

.compliance-content {
  width: 80%;
}

.compliance-content-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.8px;
  margin-bottom: 16px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .compliance-content-headline {
    margin: 16px 0px;
  }
}

.compliance-content-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  margin-bottom: 32px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .compliance-content-text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

.compliance-content-button {
  display: flex;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .compliance-content-button {
    margin-bottom: 16px;
  }
}

.compliance-pre {
  background-color: #E8ECED;
  white-space: pre-wrap;
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: Courier;
  font-size: 16px;
  line-height: 19px;
  padding: 16px;
  word-break: break-all;
  overflow: scroll;
}
.ref-wrapper {
  background-color: #B0F8F2;
  padding-top: 96px;
  padding-bottom: 104px;
}

.ref-headline {
  margin-top: 0;
  margin-bottom: 64px;
  text-align: center;
  color: #184962;
}
@media only screen and (max-width: 600px) {
  .ref-headline {
    margin-bottom: 32px;
  }
}

.ref-content-row img {
  width: 153px;
  height: 153px;
}

img.ref {
  width: 153px;
  height: 153px;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  img.ref {
    margin-bottom: 4px;
  }
}
@media only screen and (max-width: 600px) {
  img.ref {
    width: 100px;
    height: 100px;
  }
}

.ref-content-row {
  margin: 0px 32px 61px 32px;
  display: flex;
  justify-content: space-between;
}
.ref-content-row:last-child {
  margin: 0px 32px 0px 32px;
}

.ref-image {
  display: flex;
  justify-content: center;
}
::selection {
  background-color: #184962;
  color: #FFF;
}

*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

body {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #184962;
}

h1 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: "Parafina-SemiWideXBold";
  font-size: 96px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -1.5px;
}
@media only screen and (max-width: 992px) {
  h1 {
    font-size: 72px;
  }
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 60px;
  }
}

h2 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: Parafina-SemiWideXBold;
  font-size: 60px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -0.5px;
}
@media only screen and (max-width: 992px) {
  h2 {
    font-size: 56px;
  }
}
@media only screen and (max-width: 600px) {
  h2 {
    font-size: 42px;
  }
}

h3 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: Parafina-SemiWideXBold;
  font-size: 48px;
  font-weight: 900;
  line-height: 100%;
}
@media only screen and (max-width: 992px) {
  h3 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 600px) {
  h3 {
    font-size: 36px;
  }
}

h4 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: Parafina-SemiWideXBold;
  font-size: 40px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.25px;
}
@media only screen and (max-width: 992px) {
  h4 {
    font-size: 34px;
  }
}
@media only screen and (max-width: 600px) {
  h4 {
    font-size: 28px;
  }
}

h5 {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0px;
  font-family: Parafina-SemiWideBold;
  font-size: 28px;
  font-weight: 700;
  line-height: 100%;
}
@media only screen and (max-width: 992px) {
  h5 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 600px) {
  h5 {
    font-size: 20px;
  }
}

p {
  margin: 0px;
}

button {
  border: none;
}

a {
  cursor: pointer;
  color: #184962;
  text-decoration: underline;
}

li a {
  text-decoration: none;
}

iframe {
  border: none;
}

.container, .row, .container .row {
  width: inherit;
  margin: 0px auto;
}

div.row {
  max-width: 1180px;
  margin-bottom: 0px;
}

.row .col {
  padding: 0px 20px;
  margin-left: 0px;
  margin-right: 0px;
}
@media only screen and (max-width: 600px) {
  .row .col {
    padding-top: 20px;
    -webkit-hyphens: none;
            hyphens: none;
  }
}

.default-padding-top-bottom {
  padding-top: 96px;
  padding-bottom: 96px;
}
@media only screen and (max-width: 992px) {
  .default-padding-top-bottom {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}
@media only screen and (max-width: 600px) {
  .default-padding-top-bottom {
    padding-top: 42px;
    padding-bottom: 42px;
  }
}

.default-margin-top-bottom {
  margin-top: 96px;
  margin-bottom: 96px;
}
@media only screen and (max-width: 992px) {
  .default-margin-top-bottom {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}
@media only screen and (max-width: 600px) {
  .default-margin-top-bottom {
    margin-top: 42px;
    margin-bottom: 42px;
  }
}

.color-schema-dark {
  color: #FFF;
  background-color: #184962;
}

.color-schema-light {
  color: #184962;
  background-color: #B0F8F2;
}

.color-schema-white {
  color: #184962;
  background-color: #FFF;
}

.color-schema-svg-dark {
  fill: #B0F8F2;
  stroke: #B0F8F2;
}

.color-schema-svg-light {
  fill: #02F0DD;
  stroke: #02F0DD;
}

.color-schema-svg-white {
  fill: #B0F8F2;
  stroke: #B0F8F2;
}

.subtitle {
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
  text-align: left;
}

.hidden {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* Override default yellow background */
  box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  /* Set text color */
  -webkit-text-fill-color: #184962 !important;
  border: 1px solid #ccc !important;
}

.body1, .licensing-table-head th, .licensing-table-body td {
  font-feature-settings: "clig" off, "liga" off;
  text-wrap: wrap;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* for proper hyphenation */
  lang: "en";
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.body2 {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  text-align: left;
}

.body-din {
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: center;
}

.button-medium {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: left;
}

.button-large {
  font-family: "Inter-Regular";
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-align: left;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.md-96 {
  font-size: 96px;
}

.material-icons {
  color: #00B7A8;
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
@media only screen and (max-width: 600px) {
  .glide__slides {
    grid-gap: 82.5px;
    gap: 82.5px;
  }
}

.glide__slides--dragging {
  -webkit-user-select: none;
          user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  -webkit-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.glide__slide a {
  -webkit-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.glide__arrows {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}

.glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}

.glide--rtl {
  direction: rtl;
}


@media only screen and (max-width: 992px) {
  .licensing-table-wrapper {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .licensing-table-wrapper {
    margin-bottom: 48px;
  }
}

.licensing-table-head thead, .licensing-table-body tbody {
  padding: 0px 32px;
}

.licensing-table-head th {
  text-align: center;
  text-transform: uppercase;
  color: #184962;
  font-weight: normal;
}

.licensing-table-body td {
  text-align: center;
  color: #184962;
  border-right: 1px solid #E8ECED;
}
.licensing-table-body td:last-child {
  border-right: unset;
}

td .licensing-checkmark {
  text-align: center;
}

.licensing-table-left {
  text-align: left;
  padding-left: 32px;
}

.licensing-table-subheadline {
  text-transform: uppercase;
  padding: 0px 32px;
  width: 100%;
  color: #184962;
  text-align: left;
}

@media only screen and (max-width: 992px) {
  .licensing-overview-headline {
    margin: 0px 10.5px 32px 10.5px;
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {
  .licensing-overview-headline {
    margin: 48px 10.5px 16px 10.5px;
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  .licensing-overview-content {
    margin-bottom: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .licensing-overview-content {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 992px) {
  .licensing-overview-content-box {
    margin-bottom: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .licensing-overview-content-box {
    margin-bottom: 16px;
  }
}

.licensing-table-row-heading {
  background-color: #B0F8F2;
}

@media only screen and (max-width: 992px) {
  .licensing-overview-content-headline {
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 992px) {
  .licensing-overview-content-text {
    margin-bottom: 4px;
  }
}
@charset "UTF-8";
@media only screen and (max-width: 600px) {
  .imprint-wrapper {
    padding: 32px 24px;
  }
}

.imprint-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .imprint-headline {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
    margin-bottom: 16px;
  }
}

.imprint-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .imprint-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.imprint-listitem li::before {
  content: "\2022";
  margin-left: -20px;
  padding-right: 13px;
}
.whitepapers-wrapper {
  padding: 0px 32px 0px 32px;
}
@media only screen and (max-width: 600px) {
  .whitepapers-wrapper {
    padding: 32px 13.5px 0px 13.5px;
  }
}

.whitepapers-image {
  max-width: 230px;
  width: 100%;
  height: auto;
}

.whitepapers-headline {
  color: #184962;
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
}
@media only screen and (max-width: 600px) {
  .whitepapers-headline {
    font-size: 20px;
    letter-spacing: 0.67px;
    margin: 8px 0px;
  }
}

.whitepapers-text {
  color: #63737E;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 27px;
  height: 80px;
}
@media only screen and (max-width: 992px) {
  .whitepapers-text {
    height: unset;
  }
}
@media only screen and (max-width: 600px) {
  .whitepapers-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

.whitepapers-download {
  display: flex;
  justify-content: center;
}

.whitepapers-button {
  margin-bottom: 32px;
  padding: 14px 20px;
  border-radius: 3px;
  background-color: #478EB3;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  justify-content: center;
}
.whitepapers-button:hover {
  transition: 0.3s ease-in-out;
  background-color: #184962;
}
.whitepapers-button:active {
  background-color: #184962;
}
@media only screen and (max-width: 600px) {
  .whitepapers-button {
    justify-content: center;
    width: 100%;
  }
}

.whitepapers-button-text {
  color: #FFF;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  margin-left: 18px;
}
@charset "UTF-8";
.flex-center {
  justify-content: center;
  justify-items: center;
  display: flex;
}

.whitepaperarticle-even {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.row.whitepaperarticle-wrapper {
  padding: 0 12px;
}

.whitepaperarticle-headline1 {
  color: #184962;
  margin-top: 64px;
  margin-bottom: 32px;
  font-size: 48px;
  font-weight: 300;
  line-height: 57px;
}
@media only screen and (max-width: 992px) {
  .whitepaperarticle-headline1 {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .whitepaperarticle-headline1 {
    font-size: 24px;
    margin-top: 32px;
  }
}

.whitepaperarticle-headline2 {
  margin-top: 32px;
  margin-bottom: 32px;
  color: #184962;
  font-size: 24px;
  letter-spacing: 0.8px;
  line-height: 30px;
}

.whitepaperarticle-code-snippet {
  background-color: #E8ECED;
  font-family: Courier;
  font-size: 16px;
  line-height: 19px;
  padding: 16px;
  word-break: break-all;
  overflow: scroll;
}

.whitepaperarticle-quote-wrapper {
  margin: 26px 30px 30px -15vw;
  max-width: 425px;
  float: left;
}
@media only screen and (max-width: 992px) {
  .whitepaperarticle-quote-wrapper {
    margin-left: 0px;
    float: none;
    max-width: unset;
  }
}
@media only screen and (max-width: 600px) {
  .whitepaperarticle-quote-wrapper {
    margin-right: 0px;
  }
}

.whitepaperarticle-quote-image {
  float: left;
}
@media only screen and (max-width: 600px) {
  .whitepaperarticle-quote-image {
    width: 26px;
    height: 29px;
  }
}

.whitepaperarticle-quote-text .whitepaperarticle-text {
  margin: 0px;
  max-width: 360px;
  margin-left: 70px;
  color: #184962;
  padding: 16px 0px;
  font-size: 24px;
  letter-spacing: 0.8px;
  line-height: 27px;
  border-top: 10px solid #E8ECED;
  border-bottom: 10px solid #E8ECED;
}
@media only screen and (max-width: 992px) {
  .whitepaperarticle-quote-text .whitepaperarticle-text {
    max-width: 85%;
  }
}
@media only screen and (max-width: 600px) {
  .whitepaperarticle-quote-text .whitepaperarticle-text {
    font-size: 18px;
    line-height: 20px;
    max-width: 100%;
    margin-left: 40px;
  }
}

.whitepaperarticle-image-wrapper.whitepaperarticle-image {
  display: block;
  margin: auto;
  width: auto;
  height: auto;
  max-height: 350px;
  max-width: 100%;
}

.whitepaperarticle-image-description {
  color: #63737E;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.whitepaperarticle-text {
  margin-bottom: 32px;
  margin-top: 32px;
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
}
@media only screen and (max-width: 600px) {
  .whitepaperarticle-text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.whitepaperarticle-sidebar-container {
  margin: 64px 0px;
  border-top: 6px solid #E8ECED;
  border-bottom: 6px solid #E8ECED;
  max-width: 68px;
  position: sticky;
  top: 64px;
}
@media only screen and (max-width: 992px) {
  .whitepaperarticle-sidebar-container {
    position: unset;
    max-width: 100%;
  }
}

.whitepaperarticle-sidebar-icons-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .whitepaperarticle-sidebar-icons-container {
    flex-direction: row;
    border-bottom: 6px solid #E8ECED;
    border: unset;
  }
}

.whitepaperarticle-sidebar-title {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  max-width: 68px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 3.3px;
  color: #184962;
}
@media only screen and (max-width: 992px) {
  .whitepaperarticle-sidebar-title {
    display: none;
  }
}

.whitepaperarticle-sidebar-icon {
  margin: 8px 16px;
  cursor: pointer;
}
.whitepaperarticle-sidebar-icon:last-child {
  margin-bottom: 16px;
}
@media only screen and (max-width: 992px) {
  .whitepaperarticle-sidebar-icon:last-child {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 992px) {
  .whitepaperarticle-sidebar-icon {
    display: flex;
  }
}
.whitepaperarticle-sidebar-icon img:hover, .whitepaperarticle-sidebar-icon img:focus, .whitepaperarticle-sidebar-icon img:active {
  opacity: 0.8;
}

.whitepaperarticle-recommended-headline {
  margin: 124px 0px 64px 0px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  text-align: center;
  color: #184962;
}

.bold {
  font-weight: bold;
  display: inline;
}

.underline {
  text-decoration: underline;
  display: inline;
}

.italic {
  font-style: italic;
  display: inline;
}

.whitepaperarticle-listitem {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
}
@media only screen and (max-width: 600px) {
  .whitepaperarticle-listitem {
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.whitepaperarticle-listitem:before {
  content: "\2022";
  margin-left: -20px;
  padding-right: 13px;
}
.authors-wrapper {
  padding-top: 64px;
}

.authors-headline, .authors-profiles, .authors-description, .authors-position, .authors-bio-headline {
  color: #184962;
  font-size: 32px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.8px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
  .authors-headline, .authors-profiles, .authors-description, .authors-position, .authors-bio-headline {
    margin: 16px 0px;
    font-size: 24px;
  }
}

.authors-bio-headline {
  margin-top: 16px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .authors-bio-content {
    margin: 32px 0px;
  }
}

.authors-position {
  font-size: 24px;
}
@media only screen and (max-width: 600px) {
  .authors-position {
    margin: 16px 0px;
    font-size: 18px;
  }
}

.authors-description {
  font-size: 18px;
  line-height: 24px;
}
@media only screen and (max-width: 600px) {
  .authors-description {
    margin: 16px 0px;
    font-size: 14px;
  }
}

.authors-profiles {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-decoration: underline;
}
@media only screen and (max-width: 600px) {
  .authors-profiles {
    margin: 16px 0px;
    font-size: 14px;
  }
}
.authors-profiles a {
  cursor: pointer !important;
}
.authors-profiles a:hover {
  cursor: pointer;
}

.authors-pic {
  margin-right: 32px;
  display: flex;
  justify-content: left;
}
@media only screen and (max-width: 600px) {
  .authors-pic {
    margin-right: 0px;
    justify-content: center;
  }
}

.authors-posts-wrapper {
  max-width: 1180px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.blogpost-small-wrapper {
  display: flex;
  flex: 1 1 250px;
  flex-direction: column;
  margin: 32px 16px;
  padding-bottom: 32px;
  background-color: #E8ECED;
  max-width: 500px;
}
@media only screen and (max-width: 992px) {
  .blogpost-small-wrapper {
    max-width: 100%;
  }
}

.blogpost-small-image-wrapper {
  width: 100%;
  height: 120px;
  overflow: hidden;
  position: relative;
  transition: 0.3s ease-in-out;
}
@media only screen and (max-width: 992px) {
  .blogpost-small-image-wrapper {
    height: 200px;
  }
}
.blogpost-small-image-wrapper:hover {
  filter: grayscale(0);
}

img.blogpost-feed-image {
  margin: auto;
  max-height: auto;
  max-width: 100%;
  object-fit: cover;
  transition: 0.3s;
}
img.blogpost-feed-image:hover {
  transition: 0.3s;
  transform: scale(1.05);
}
@media only screen and (max-width: 992px) {
  img.blogpost-feed-image {
    object-fit: scale-down;
  }
}

.blogpost-headline {
  padding: 32px 32px 0 32px;
  color: #184962;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .blogpost-headline {
    font-size: 16px;
    line-height: 24px;
  }
}

.blogpost-info-headline {
  padding: 0 32px;
  color: #184962;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 3.3px;
}

.blogpost-paragraph {
  color: #184962;
  padding: 0 32px;
  margin-bottom: 36px;
  font-size: 18px;
  line-height: 27px;
}
@media only screen and (max-width: 600px) {
  .blogpost-paragraph {
    font-size: 16px;
    line-height: 24px;
  }
}

.blogpost-info-link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #02F0DD;
  padding-right: 32px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 15px;
  font-weight: 700;
  margin-top: auto;
}

.blogpost-arrow {
  margin-left: 8px;
}
.presentations-wrapper {
  padding: 64px 32px 0px 32px;
}
@media only screen and (max-width: 600px) {
  .presentations-wrapper {
    padding: 32px 13.5px 0px 13.5px;
  }
}

.presentations-image {
  max-width: 530px;
  width: 100%;
  height: auto;
}

.presentations-headline {
  color: #184962;
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
}
@media only screen and (max-width: 600px) {
  .presentations-headline {
    font-size: 20px;
    letter-spacing: 0.67px;
    margin: 8px 0px;
  }
}

.presentations-text {
  color: #63737E;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 27px;
  height: 80px;
}
@media only screen and (max-width: 992px) {
  .presentations-text {
    height: unset;
  }
}
@media only screen and (max-width: 600px) {
  .presentations-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

.presentations-download {
  display: flex;
}

.presentations-button {
  margin-bottom: 96px;
  margin-top: 64px;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.presentations-button:hover {
  transition: 0.3s ease-in-out;
}
.presentations-button:active {
  background-color: #02F0DD;
}
@media only screen and (max-width: 600px) {
  .presentations-button {
    justify-content: center;
    width: 100%;
    margin-bottom: 64px;
  }
}

.security-wrapper {
  margin: 32px 32px;
}
@media only screen and (max-width: 600px) {
  .security-wrapper {
    margin: 32px 24px;
  }
}

.security-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  padding-top: 32px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .security-headline {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
    margin-bottom: 16px;
  }
}

.security-text {
  font-family: "Courier New", Courier, monospace;
  word-wrap: break-word;
  background-color: #E8ECED;
  padding: 16px;
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .security-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.security-plain-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 96px;
}
@media only screen and (max-width: 600px) {
  .security-plain-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}
.advisory-wrapper {
  padding: 64px 32px 128px 32px;
}
@media only screen and (max-width: 600px) {
  .advisory-wrapper {
    padding: 32px 24px;
  }
}

.advisory-info-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .advisory-info-headline {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
    margin-bottom: 16px;
  }
}

.advisory-info-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .advisory-info-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 992px) {
  .advisory-table-wrapper {
    overflow: auto;
    white-space: nowrap;
  }
}

.advisory-table-head th {
  padding: 16px;
  color: #184962;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 3.3px;
  text-transform: uppercase;
}

.advisory-table-row td {
  padding: 16px;
  color: #63737E;
  font-size: 14px;
  line-height: 20px;
  border-right: 1px solid;
}
.advisory-table-row td:last-child {
  border-right: unset;
}

th.advisory-table-first {
  min-width: 180px;
}

th.advisory-table-second {
  min-width: 130px;
}
@charset "UTF-8";
.disclosure-wrapper {
  padding: 64px 32px;
}
@media only screen and (max-width: 600px) {
  .disclosure-wrapper {
    padding: 32px 24px;
  }
}

.disclosure-main-headline {
  color: #184962;
  font-size: 48px;
  line-height: 57px;
  font-weight: 300;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .disclosure-main-headline {
    margin: 32px 0px 16px 0px;
    font-size: 36px;
    line-height: 43px;
    color: #184962;
  }
}

.disclosure-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .disclosure-headline {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
    margin-bottom: 16px;
  }
}

.disclosure-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .disclosure-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}
.disclosure-text a {
  color: #63737E;
  text-decoration: none;
}

ul {
  margin-left: 20px !important;
}

.disclosure-list li:before {
  content: "\2022";
  margin-left: -20px;
  padding-right: 13px;
}
.disclosure-list li a {
  color: #63737E;
  text-decoration: none;
}
.notfound-wrapper {
  display: flex;
  /* height: 100vh; */
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 32px 24px;
}

.notfound-warning {
  color: #00B7A8;
  font-size: 64px;
  line-height: 140px;
  font-weight: 500;
  letter-spacing: 4px;
}
@media only screen and (max-width: 992px) {
  .notfound-warning {
    font-size: 32px;
    line-height: 72px;
    letter-spacing: 2px;
  }
}

.notfound-headline {
  color: #00B7A8;
  font-size: 42px;
  margin-bottom: 16px;
  margin-top: 32px;
}
@media only screen and (max-width: 992px) {
  .notfound-headline {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 992px) {
  .notfound-headline {
    font-size: 24px;
    line-height: 28px;
  }
}

.notfound-text, .notfound-text-list {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-bottom: 12px;
}
@media only screen and (max-width: 992px) {
  .notfound-text, .notfound-text-list {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
  }
}

.notfound-text-list {
  line-height: 48px;
}
@media only screen and (max-width: 992px) {
  .notfound-text-list {
    line-height: 40px;
  }
}

.notfound-button {
  display: flex;
  justify-content: center;
}
.thanks-wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 0 24px;
}

.thanks-warning {
  letter-spacing: 4px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 992px) {
  .thanks-warning {
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 2px;
  }
}

.thanks-headline {
  margin-bottom: 16px;
}
@media only screen and (max-width: 992px) {
  .thanks-headline {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 992px) {
  .thanks-headline {
    font-size: 24px;
    line-height: 28px;
  }
}

.thanks-text {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-bottom: 64px;
}
@media only screen and (max-width: 992px) {
  .thanks-text {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
  }
}

.thanks-button {
  display: flex;
  justify-content: center;
}
.company-integrators-wrapper {
  padding-top: 96px;
  padding-bottom: 104px;
}

.company-integrators-headline {
  margin-bottom: 64px;
  color: #184962;
  font-size: 48px;
  line-height: 57px;
  text-align: center;
  font-weight: 300;
}
@media only screen and (max-width: 992px) {
  .company-integrators-headline {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .company-integrators-headline {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 28px;
  }
}

.company-integrators-section {
  border-bottom: 2px solid;
  margin-bottom: 64px;
  display: flex;
  justify-content: space-around;
  max-width: 945px;
}
.company-integrators-section:last-child {
  border-bottom: unset;
}
@media only screen and (max-width: 600px) {
  .company-integrators-section {
    border-bottom: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .company-integrators-section:last-child {
    margin-bottom: 0px;
  }
}

.integrators-pic img {
  margin-right: 32px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .integrators-pic img {
    margin-right: 0px;
  }
}

.integrators-content-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.8px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
  .integrators-content-headline {
    margin: 16px 0px;
  }
}

.integrators-content-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .integrators-content-text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

.integrators-content-button {
  display: flex;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .integrators-content-button {
    margin-bottom: 16px;
  }
}

.heise-textbox {
  margin-bottom: 25px;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.heise-image-wrapper.heise-image {
  display: block;
  margin: auto;
  width: auto;
  height: auto;
  max-height: 500px;
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 60px;
}

.heise-tilesimple-headline {
  color: #184962;
  font-weight: normal !important;
  font-size: 48px;
  line-height: 57px;
  font-weight: 300;
  margin-bottom: 32px;
  margin-top: 0px;
}
@media only screen and (max-width: 600px) {
  .heise-tilesimple-headline {
    font-size: 24px;
    line-height: 28px;
  }
}

.heise-tilesimple-text {
  font-weight: normal !important;
  font-size: 22px;
  line-height: 34px;
  color: #63737E;
}
@media only screen and (max-width: 600px) {
  .heise-tilesimple-text {
    font-size: 16px;
    line-height: 24px;
  }
}
.certifications-wrapper {
  padding: 0px 32px;
}
@media only screen and (max-width: 600px) {
  .certifications-wrapper {
    padding: 0px 13.5px;
  }
}

.certifications-headline {
  text-align: center;
  font-size: 48px;
  line-height: 57px;
  color: #184962;
  margin-top: 64px;
  margin-bottom: 64px;
  font-weight: 300;
}
@media only screen and (max-width: 992px) {
  .certifications-headline {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .certifications-headline {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 32px;
  }
}

.certifications-image {
  background-color: #E8ECED;
  display: flex;
  justify-content: center;
}
.certifications-image img {
  margin: 16px;
}
@media only screen and (max-width: 600px) {
  .certifications-image {
    margin-bottom: 32px;
  }
}

.certifications-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .certifications-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

div.col.s12.l6.certifications-button {
  margin-right: 20px;
  max-width: 300px;
}
@media only screen and (max-width: 600px) {
  div.col.s12.l6.certifications-button {
    max-width: 100%;
  }
}

.certifications-button-wrapper {
  margin-bottom: 32px;
}

.certifications-button {
  background-color: #184962;
  border-radius: 3px;
  display: flex;
  justify-content: space-around;
  color: #FFF;
  margin: 0px 30px 32px 0px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .certifications-button {
    margin-bottom: 16px;
  }
}

.certifications-button-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 14px 20px;
}

.certifications-bottom-line {
  border-bottom: 3px solid #E8ECED;
}
@charset "UTF-8";
.press-profile-pic {
  width: 100%;
  margin-top: 32px;
}

.row.pressarticle-wrapper {
  padding: 0 12px;
  margin-left: 100px;
  margin-right: 100px;
  max-width: 100%;
}
@media only screen and (max-width: 992px) {
  .row.pressarticle-wrapper {
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .row.pressarticle-wrapper {
    padding: 0 6px;
  }
}

.pressarticle-headline1 {
  color: #184962;
  margin-top: 64px;
  margin-bottom: 32px;
  font-size: 48px;
  font-weight: 300;
  line-height: 57px;
}
@media only screen and (max-width: 992px) {
  .pressarticle-headline1 {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .pressarticle-headline1 {
    font-size: 24px;
    margin-top: 32px;
  }
}

.pressarticle-headline2 {
  margin-top: 32px;
  margin-bottom: 32px;
  color: #184962;
  font-size: 24px;
  letter-spacing: 0.8px;
  line-height: 30px;
  text-align: center;
}

.pressarticle-headline3 {
  margin-top: 32px;
  margin-bottom: 18px;
  color: #184962;
  font-size: 22px;
  letter-spacing: 0.8px;
  line-height: 30px;
  text-align: center;
}

.pressarticle-code-snippet {
  background-color: #E8ECED;
  font-family: Courier;
  font-size: 16px;
  line-height: 19px;
  padding: 16px;
  word-break: break-all;
  overflow: scroll;
}

.pressarticle-quote-wrapper {
  margin: 26px 30px 30px -15vw;
  max-width: 425px;
  float: left;
}
@media only screen and (max-width: 992px) {
  .pressarticle-quote-wrapper {
    margin-left: 0px;
    float: none;
    max-width: unset;
  }
}
@media only screen and (max-width: 600px) {
  .pressarticle-quote-wrapper {
    margin-right: 0px;
  }
}

.pressarticle-quote-image {
  float: left;
}
@media only screen and (max-width: 600px) {
  .pressarticle-quote-image {
    width: 26px;
    height: 29px;
  }
}

.pressarticle-quote-text .pressarticle-text {
  margin: 0px;
  max-width: 360px;
  margin-left: 70px;
  color: #184962;
  padding: 16px 0px;
  font-size: 24px;
  letter-spacing: 0.8px;
  line-height: 27px;
  border-top: 10px solid #E8ECED;
  border-bottom: 10px solid #E8ECED;
}
@media only screen and (max-width: 992px) {
  .pressarticle-quote-text .pressarticle-text {
    max-width: 85%;
  }
}
@media only screen and (max-width: 600px) {
  .pressarticle-quote-text .pressarticle-text {
    font-size: 18px;
    line-height: 20px;
    max-width: 100%;
    margin-left: 40px;
  }
}

.pressarticle-image-subline {
  margin-bottom: 32px;
  margin-top: 6px;
  color: #63737E;
  font-size: 14px;
  line-height: 27px;
}
@media only screen and (max-width: 600px) {
  .pressarticle-image-subline {
    font-size: 12px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.pressarticle-image-wrapper.pressarticle-image {
  display: block;
  margin: auto;
  width: auto;
  height: auto;
  max-height: 350px;
  max-width: 100%;
}

.pressarticle-image-description {
  color: #63737E;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.pressarticle-text {
  margin-bottom: 32px;
  margin-top: 32px;
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
}
@media only screen and (max-width: 600px) {
  .pressarticle-text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.pressarticle-sidebar-container {
  margin: 64px 0px;
  border-top: 6px solid #E8ECED;
  border-bottom: 6px solid #E8ECED;
  max-width: 68px;
  position: sticky;
  top: 64px;
}
@media only screen and (max-width: 992px) {
  .pressarticle-sidebar-container {
    position: unset;
    max-width: 100%;
  }
}

.pressarticle-sidebar-icons-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .pressarticle-sidebar-icons-container {
    flex-direction: row;
    border-bottom: 6px solid #E8ECED;
    border: unset;
  }
}

.pressarticle-sidebar-title {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  max-width: 68px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 3.3px;
  color: #184962;
}
@media only screen and (max-width: 992px) {
  .pressarticle-sidebar-title {
    display: none;
  }
}

.pressarticle-sidebar-icon {
  margin: 8px 16px;
  cursor: pointer;
}
.pressarticle-sidebar-icon:last-child {
  margin-bottom: 16px;
}
@media only screen and (max-width: 992px) {
  .pressarticle-sidebar-icon:last-child {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 992px) {
  .pressarticle-sidebar-icon {
    display: flex;
  }
}
.pressarticle-sidebar-icon img:hover, .pressarticle-sidebar-icon img:focus, .pressarticle-sidebar-icon img:active {
  opacity: 0.8;
}

.pressarticle-recommended-headline {
  margin: 124px 0px 64px 0px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  text-align: center;
  color: #184962;
}

.bold {
  font-weight: bold;
  display: inline;
}

.underline {
  text-decoration: underline;
  display: inline;
}

.italic {
  font-style: italic;
  display: inline;
}

.pressarticle-listitem {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
}
@media only screen and (max-width: 600px) {
  .pressarticle-listitem {
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.pressarticle-listitem:before {
  content: "\2022";
  margin-left: -20px;
  padding-right: 13px;
}
.prefooter-announcement-container {
  background-color: #f5f7f7;
}

.prefooter-announcement-button-spacer {
  margin-top: 30px;
}

.prefooter-announcement-row {
  padding: 64px 32px;
}
@media only screen and (max-width: 992px) {
  .prefooter-announcement-row {
    padding: 0px 16px 32px 16px;
  }
}

.prefooter-announcement-content-wrapper {
  padding: 0px;
}
@media only screen and (max-width: 992px) {
  .prefooter-announcement-content-wrapper {
    margin: 32px 0px;
  }
}
@media only screen and (max-width: 600px) {
  .prefooter-announcement-content-wrapper {
    margin: 16px 0px;
  }
}

@media only screen and (max-width: 992px) and (min-width: 600px) {
  .prefooter-announcement-col-content {
    padding: 0 12px;
  }
}

.prefooter-announcement-headline {
  color: #184962;
  text-transform: uppercase;
  font-size: 48px;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  line-height: 100%;
}

.prefooter-announcement-content {
  margin: 8px 0px 0px 0px;
}
@media only screen and (max-width: 600px) {
  .prefooter-announcement-content {
    margin-top: 8px;
  }
}

.prefooter-announcement-content-text {
  color: #63737E;
  font-size: 18px;
  padding-top: 4px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 992px) and (min-width: 600px) {
  .prefooter-announcement-content-text {
    padding-top: 8px;
    margin-bottom: 12px;
  }
}
.company-integrators-wrapper {
  padding-top: 96px;
  padding-bottom: 104px;
}

.company-integrators-headline {
  margin-bottom: 64px;
  color: #184962;
  font-size: 48px;
  line-height: 57px;
  text-align: center;
  font-weight: 300;
}
@media only screen and (max-width: 992px) {
  .company-integrators-headline {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .company-integrators-headline {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 28px;
  }
}

.company-integrators-section {
  border-bottom: 2px solid;
  margin-bottom: 64px;
  display: flex;
  justify-content: space-around;
  max-width: 945px;
}
.company-integrators-section:last-child {
  border-bottom: unset;
}
@media only screen and (max-width: 600px) {
  .company-integrators-section {
    border-bottom: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .company-integrators-section:last-child {
    margin-bottom: 0px;
  }
}

.integrators-pic img {
  margin-right: 32px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .integrators-pic img {
    margin-right: 0px;
  }
}

.integrators-content-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.8px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
  .integrators-content-headline {
    margin: 16px 0px;
  }
}

.integrators-content-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .integrators-content-text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

.integrators-content-button {
  display: flex;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .integrators-content-button {
    margin-bottom: 16px;
  }
}

.newsletter-wrapper {
  padding-top: 0px;
}
@media only screen and (max-width: 992px) {
  .newsletter-wrapper {
    padding: 0px 32px 0px 32px;
  }
}
@media only screen and (max-width: 600px) {
  .newsletter-wrapper {
    padding: 0px 24px 16px 24px;
  }
}

.row.newsletter {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.newsletter-headline {
  margin-bottom: 32px;
  margin-top: 0px;
  color: #184962;
  font-size: 48px;
  line-height: 57px;
  text-align: center;
  font-weight: 300;
}
@media only screen and (max-width: 992px) {
  .newsletter-headline {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 600px) {
  .newsletter-headline {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 28px;
  }
}

.newsletter-section {
  margin-bottom: 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  max-width: 945px;
  width: 100%;
}
.newsletter-section:last-child {
  border-bottom: unset;
}
@media only screen and (max-width: 600px) {
  .newsletter-section {
    border-bottom: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .newsletter-section:last-child {
    margin-bottom: 0px;
  }
}

.newsletter-content {
  width: 80%;
}

.newsletter-content-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.8px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
  .newsletter-content-headline {
    margin: 16px 0px;
  }
}

.newsletter-content-text {
  color: #184962;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .newsletter-content-text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

.newsletter-textbox {
  margin-bottom: 25px;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.newsletter-image-wrapper.newsletter-image {
  display: block;
  margin: auto;
  width: auto;
  height: auto;
  max-height: 500px;
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 60px;
}

.newsletter-tilesimple-headline {
  color: #184962;
  font-weight: normal !important;
  font-size: 48px;
  line-height: 57px;
  font-weight: 300;
  margin-bottom: 32px;
  margin-top: 0px;
}
@media only screen and (max-width: 600px) {
  .newsletter-tilesimple-headline {
    font-size: 24px;
    line-height: 28px;
  }
}

.newsletter-tilesimple-text {
  font-weight: normal !important;
  font-size: 22px;
  line-height: 34px;
  color: #184962;
}
@media only screen and (max-width: 600px) {
  .newsletter-tilesimple-text {
    font-size: 16px;
    line-height: 24px;
  }
}
.error-wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 0 24px;
}

.error-warning {
  color: #00B7A8;
  font-size: 60px;
  line-height: 140px;
  font-weight: 500;
  letter-spacing: 4px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 992px) {
  .error-warning {
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 2px;
  }
}

.error-headline {
  color: #184962;
  font-size: 48px;
  line-height: 57px;
  font-weight: 300;
  margin-bottom: 16px;
}
@media only screen and (max-width: 992px) {
  .error-headline {
    font-size: 36px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 992px) {
  .error-headline {
    font-size: 24px;
    line-height: 28px;
  }
}

.error-text {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-bottom: 64px;
}
@media only screen and (max-width: 992px) {
  .error-text {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
  }
}

.error-button {
  display: flex;
  justify-content: center;
}

