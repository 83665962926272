@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.aim-section {
  margin-bottom: 12px;
  margin-top: 12px;
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  width: 100%;
  &:last-child {
    border-bottom: unset;
  }
  @media only screen and (max-width: $mobile) {
    border-bottom: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}