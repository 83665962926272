@charset "UTF-8";
.faq-row-wrapper {
  padding: 64px 32px;
}
@media only screen and (max-width: 600px) {
  .faq-row-wrapper {
    padding: 32px 24px;
  }
}

.faq-question-section {
  margin-bottom: 48px;
  max-width: 75%;
}
@media only screen and (max-width: 992px) {
  .faq-question-section:last-child {
    margin-bottom: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .faq-question-section {
    margin-bottom: 32px;
    max-width: 100%;
  }
}

.faq-question-headline, .faq-answer-section-headline {
  color: #184962;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 3.3px;
}

@media only screen and (max-width: 992px) {
  .faq-answer-section-headline {
    padding-top: 64px;
  }
  .faq-answer-section-headline:first-child {
    padding-top: 32px;
  }
}

.faq-question-text {
  font-size: 18px;
  padding-bottom: 16px;
  line-height: 27px;
}
@media only screen and (max-width: 992px) {
  .faq-question-text {
    font-size: 16px;
    line-height: 24px;
  }
}
.faq-question-text a {
  color: #184962;
}

.faq-answer-section {
  border-left: 3px solid #E8ECED;
  padding-left: 100px;
}
@media only screen and (max-width: 992px) {
  .faq-answer-section {
    margin-bottom: 64px;
    border-left: unset;
    padding-left: 0px;
  }
  .faq-answer-section:first-child {
    border-top: 3px solid #E8ECED;
  }
}
@media only screen and (max-width: 992px) {
  .faq-answer-section:last-child {
    margin-bottom: 0px;
  }
}

.faq-answer-headline {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 30px;
  color: #184962;
  letter-spacing: 0.8px;
}
@media only screen and (max-width: 600px) {
  .faq-answer-headline {
    font-size: 20px;
    letter-spacing: 0.67px;
    margin-bottom: 16px;
  }
}

.faq-answer-text {
  font-size: 18px;
  line-height: 27px;
  color: #63737E;
  padding-bottom: 96px;
}
@media only screen and (max-width: 992px) {
  .faq-answer-text {
    padding-bottom: 64px;
  }
}
@media only screen and (max-width: 600px) {
  .faq-answer-text {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 32px;
  }
}
.faq-answer-text ul li:before {
  content: "•";
  margin-right: 20px;
  margin-left: -10px;
}

.faq-answer-links {
  word-break: break-all;
}