@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.blog-categories {
  &-wrapper {
    padding: 0 2.4rem;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &-title {
    font-size: 12px;
    line-height: 32px;
    letter-spacing: 3.3px;
    color: $primary;
    text-transform: uppercase;
  }
  &-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  &-item {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 20px;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 3px;
    margin-bottom: 10px;
    transition: all .2s;
    &:hover {
      color: $white;
      background-color: $primary;
    }
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}