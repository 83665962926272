@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.thanks-wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 0 24px;
}

.thanks-warning {
  letter-spacing: 4px;
  margin-bottom: 32px;

  @media only screen and (max-width: $tabletBig) {
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 2px;
  }
}

.thanks-headline {
  margin-bottom: 16px;
  @media only screen and (max-width: $tabletBig) {
    font-size: 36px;
    line-height: 43px;
  }

  @media only screen and (max-width: $tabletBig) {
    font-size: 24px;
    line-height: 28px;
  }
}

.thanks-text {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-bottom: 64px;

  @media only screen and (max-width: $tabletBig) {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
  }
}

.thanks-button {
  display: flex;
  justify-content: center;
}