@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.code-highlight {
  position: relative;
}

.code-highlight .copy-code {
  font-size: 18px;
  cursor: pointer;
  top: 0;
  right: 0;
  font-weight: 400;
  position: absolute;
  border: 1px solid #dae0e7;
  padding: 9px 10px;
  border-radius: 0 3px;
  color: black;
  background-color: #f6f7f8;
  transition: color .25s ease;
}

.code-highlight .copy-code:before {
  content: '\e14d';
  font-family: Material Icons;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 4px;
}