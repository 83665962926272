.badge-style {
    display: inline-flex;
    padding: 0.2em 0.5em;
    font-size: 0.8em;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border-radius: 0.25rem;
    height: 2rem;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;    
}