@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.faq-wrapper {
  padding: 128px 0px;
  @media only screen and (max-width: $tabletBig) {
    padding: 64px 32px;
  };
  @media only screen and (max-width: $mobile) {
    padding: 64px 24px 32px 24px;
  };
}

.faq-headline {
  text-align: center;
  font-size: 48px;
  line-height: 57px;
  font-weight: 300;
  color: $primary;
  padding-bottom: 55px;
  @media only screen and (max-width: $tabletBig) {
    font-size: 36px;
    line-height: 43px;
    padding-bottom: 0px;
  };
  @media only screen and (max-width: $mobile) {
    font-size: 24px;
    line-height: 28px;
  };
}

.faq-info-boxes {
  display: flex;
  justify-content: space-evenly;
  margin: 0px 18px 64px 18px;
  @media only screen and (max-width: $tabletBig) {
    display: inline;
    margin-bottom: 32px;
  };
}

.faq-info-box {
  border: 3px solid $lightGrey;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 360px;
  padding: 32px;
  margin: 0px 15px;
  &:hover {
    border-color: $secondary;
  };
  @media only screen and (max-width: $tabletBig) {
    margin: 32px 0px;
    width: 100%;
    height: 280px;
  };
  @media only screen and (max-width: $mobile) {
    padding: 16px;
  };
}

.faq-info-box-headline, .faq-info-box-text  {
  font-size: 18px;
  line-height: 27px;
  color: $darkGrey;
  @media only screen and (max-width: $mobile) {
    font-size: 16px;
    line-height: 24px;
  };
}

.faq-info-box-headline {
  font-weight: bold;
  margin-bottom: 27px;
  @media only screen and (max-width: $tabletBig) {
    margin-bottom: 27px;
  };
}

.faq-button {
  display: flex;
  justify-content: center;
}