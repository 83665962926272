@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.alerting-wrapper {
  background-color: $secondary-light;
  padding-top: 96px;
  padding-bottom: 104px;
  // @media only screen and (max-width: $tabletBig) {
  //   padding: 64px 32px 0px 32px;
  // }
  // @media only screen and (max-width: $mobile) {
  //   padding: 32px 24px 16px 24px;
  // }
}

.row.alerting-row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  // @media only screen and (max-width: $tabletBig) {
  //   padding: 64px 32px 0px 32px;
  // }
  @media only screen and (max-width: $mobile) {
    flex-direction: column;
  }
}

.alerting-headline {
  margin-bottom: 32px;
  color: $primary;
  text-align: center;
}

.alerting-section {
  margin-bottom: 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  max-width: 945px;
  width: 100%;
  &:last-child {
    border-bottom: unset;
  }
  @media only screen and (max-width: $mobile) {
    border-bottom: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.alerting-pic img {
  margin-right: 32px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: $mobile) {
    margin-right: 0px;
  }
}

.alerting-content {
  text-align: center;
}

.alering-col-headline {
  margin-bottom: 32px;
  text-align: center;
  color: $white;
  @media only screen and (max-width: $tabletBig) {
    line-height: 43px;
  }
  @media only screen and (max-width: $mobile) {
    margin-bottom: 32px;
  }
}

.alerting-connectors-row-wrapper {
  padding-bottom: 72px;
  @media only screen and (max-width: $mobile) {
    padding-bottom: 0px;
  }
}

.alerting-col-text {
  text-align: center;
  color: $white;
  @media only screen and (max-width: $tabletBig) {
    margin: 0px 16px;
  }
  @media only screen and (max-width: $mobile) {
    margin-bottom: 64px;
    margin-left: auto;
    margin-right: auto;
  }
}

.alerting-col-img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 140px;
  @media only screen and (max-width: $tabletBig) {
    background-color: unset;
    height: unset;
    width: unset;
  }
}

.alerting-content-headline {
  color: $primary;
  text-align: center;
  margin-bottom: 16px;
  @media only screen and (max-width: $mobile) {
    margin: 16px 0px;
  }
}

.alerting-content-text {
  text-align: center;
  margin-bottom: 32px;
  @media only screen and (max-width: $mobile) {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

.alerting-content-button {
  display: flex;
  margin-bottom: 64px;
  @media only screen and (max-width: $mobile) {
    margin-bottom: 16px;
  }
}

.alerting-pre {
  background-color: $lightGrey;
  white-space: pre-wrap;
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: Courier;
  font-size: 16px;
  line-height: 19px;
  padding: 16px;
  word-break: break-all;
  overflow: scroll;
}

.alerting-connectors-wrapper {
  background-color: $primary;
}

.alerting-escalation-wrapper {
  background-color: $white;
}

.alerting-connectors-headline {
  margin-bottom: 64px;
  text-align: center;
  color: $white;
  @media only screen and (max-width: $mobile) {
    margin-bottom: 32px;
  }
}

.alerting-icon {
  color: $white;
}

.video-margin-bottom {
  margin-bottom: 50px;
}
