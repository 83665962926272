@charset "UTF-8";
.disclosure-wrapper {
  padding: 64px 32px;
}
@media only screen and (max-width: 600px) {
  .disclosure-wrapper {
    padding: 32px 24px;
  }
}

.disclosure-main-headline {
  color: #184962;
  font-size: 48px;
  line-height: 57px;
  font-weight: 300;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .disclosure-main-headline {
    margin: 32px 0px 16px 0px;
    font-size: 36px;
    line-height: 43px;
    color: #184962;
  }
}

.disclosure-headline {
  color: #184962;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .disclosure-headline {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
    margin-bottom: 16px;
  }
}

.disclosure-text {
  color: #63737E;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 64px;
}
@media only screen and (max-width: 600px) {
  .disclosure-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}
.disclosure-text a {
  color: #63737E;
  text-decoration: none;
}

ul {
  margin-left: 20px !important;
}

.disclosure-list li:before {
  content: "•";
  margin-left: -20px;
  padding-right: 13px;
}
.disclosure-list li a {
  color: #63737E;
  text-decoration: none;
}