@use '../../styles/Colors.scss' as *;
@use '../../styles/Breakpoints' as *;

.footer-container {
  background-color: $primary;
}

.footer-wrapper {
  color: $white;
}

div.row.footer-row {
  padding: 40px 0px;
}

.footer-links-column-container {
  margin-bottom: 32px;
  @media only screen and (max-width: $mobile) {
    margin-bottom: 0px;
  }
}

.footer-list-items {
  margin-bottom: 64px;

}

a.footer-links {
  text-decoration: none;
  color: $white;
}

.footer-list-headline a, .footer-list-item a {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  margin-left: 4.75px;
  color: $white;
  &:hover {
    color: rgba(255, 255, 255, 0.85);
  }
  @media only screen and (max-width: $mobile) {
    line-height: 48px;
  }
}

.footer-list-headline {
  margin-bottom: 32px;
  font-weight: bold;
  text-transform: capitalize;
  @media only screen and (max-width: $mobile) {
    margin-bottom: 0px;
    position: relative;
    text-align: center;
    top: -16px;
  }
}

.footer-list-item {
  margin-bottom: 16px;
  @media only screen and (max-width: $mobile) {
    text-align: center;
    margin-bottom: 0px;
  };
  &:last-child {
    position: relative;
    top: 21px;
    margin-top: -21px;
    @media only screen and (max-width: $mobile) {
      top: 32px;
      margin-top: -32px;
    };
  };
}

.footer-text {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 32px 64px 32px;
  color: $white;
  @media only screen and (max-width: $mobile) {
    padding-bottom: 32px;
  }
}

.footer-content-wrapper {
  padding: 0px;
  @media only screen and (max-width: $tabletBig) {
    margin: 32px 0px;
  };
  @media only screen and (max-width: $mobile) {
    margin: 16px 0px;
  };
};

.footer-col-content {
  @media only screen and (max-width: $tabletBig) and (min-width: $mobile) {
    padding: 0 12px;
  }
}

.footer-headline {
  color: $white;
  @media only screen and (max-width: $mobile) {
    text-align: center;
    margin-bottom: 0px;
  };
}

.footer-content {
  margin: 24px 0px 0px 0px;
  @media only screen and (max-width: 600px) {
    margin-top: 8px;
  };
}

.footer-content-icons {
  display: flex;
  justify-content: flex-start;
  gap: 32px;
  align-content: center;
  align-items: center;  
}

hr.footer-hr {
  display: none;
  height: 1.75px;
  background-color: $white;
  margin-top: 24px;
  @media only screen and (max-width: 600px) {
    display: block;
  };
}