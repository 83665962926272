@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.notfound-wrapper {
  display: flex;
  /* height: 100vh; */
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 32px 24px;
}

.notfound-warning {
  color: $secondary-dark;
  font-size: 64px;
  line-height: 140px;
  font-weight: 500;
  letter-spacing: 4px;
  @media only screen and (max-width: $tabletBig) {
    font-size: 32px;
    line-height: 72px;
    letter-spacing: 2px;
  }
}


.notfound-headline {
  color: $secondary-dark;
  font-size: 42px;
  margin-bottom: 16px;
  margin-top: 32px;
  @media only screen and (max-width: $tabletBig) {
    font-size: 36px;
    line-height: 43px;
  }
  @media only screen and (max-width: $tabletBig) {
    font-size: 24px;
    line-height: 28px;
  }
}

.notfound-text {
  color: $primary;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  margin-bottom: 12px;
  @media only screen and (max-width: $tabletBig) {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.67px;
  }
}

.notfound-text-list {
  @extend .notfound-text;
  line-height: 48px;
  @media only screen and (max-width: $tabletBig) {
    line-height: 40px;
  }
}

.notfound-button {
  display: flex;
  justify-content: center;
}