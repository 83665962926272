@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.blog-wrapper {
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.blog-searchbar {
  margin: 32px;
}

.blog-back {
  text-align: center;
  letter-spacing: 1.5px;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  display: flex;
  font-weight: 700;
  justify-content: center;
  text-align: center;
  margin: 32px 0;
}

.blog-arrow-back {
  margin-right: 8px;
}