@use '../../styles/Colors.scss' as *;
@use '../../styles/Breakpoints' as *;

.blogtitle-container, .blogtitle-subtext-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  background-color: $white;
}

.blogtitle-subtext-container {
  padding-bottom: 32px;
  @media only screen and (max-width: $tabletBig) {
    padding: 32px 32px 0px 32px;
    background-color: $white;
    color: $primary-dark;
  };
  @media only screen and (max-width: $mobile) {
    padding: 16px 24px 0px 24px;
  };
}

.blogtitle-wrapper {
  margin-top: 50px;
  max-width: 1180px;
  padding: 32px 20px 0px 20px;
  @media only screen and (max-width: $tabletBig) {
    margin: 24px 32px 48px 32px;
  };
  @media only screen and (max-width: $mobile) {
    margin: 50px 24px;
    padding: 0;
  };
}

.blogtitle-text {
  text-transform: uppercase;
  text-align: center;
  max-width: 1180px;
  color: $primary;
  margin-bottom: 32px;
  margin-top: 32px;
  @media only screen and (max-width: $tabletBig) {
  };
  @media only screen and (max-width: $mobile) {
    margin-bottom: 0px;
  };
}

.blogtitle-sub-text {
  display: flex;
  justify-content: space-evenly;
  text-transform: uppercase;
  margin: 0px;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 3.3px;
  color: $primary-dark;
  @media only screen and (max-width: $tabletBig) {
    color: $primary-dark;
  };
}

.blogtitle-tag {
  padding: 0 4px;
  color: $primary;
  transition: all .2s;
  &:not(:last-child) {
    &::after {
      content: ', '
    }
  }
  &:hover {
    color: $primary-light;
  }
  @media only screen and (max-width: $tabletBig) {
    color: $primary;
  };
}