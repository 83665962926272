@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.presentations-wrapper {
  padding: 64px 32px 0px 32px;
  @media only screen and (max-width: $mobile) {
    padding: 32px 13.5px 0px 13.5px;
  };
}

.presentations-image {
  max-width: 530px;
  width: 100%;
  height: auto;
}
  
.presentations-headline {
  color: $primary;
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.8px;
  @media only screen and (max-width: $mobile) {
    font-size: 20px;
    letter-spacing: 0.67px;
    margin: 8px 0px;
  };
}
  
.presentations-text {
  color: $darkGrey;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 27px;
  height: 80px;
  @media only screen and (max-width: $tabletBig) {
    height: unset;
  };
  @media only screen and (max-width: $mobile) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  };
}

.presentations-download {
  display: flex;
}

.presentations-button {
  margin-bottom: 96px;
  margin-top: 64px;
  display: flex;
  width: fit-content;
  cursor: pointer;
  &:hover {
    transition: 0.3s ease-in-out;
  };
  &:active {
    background-color: $secondary;
  }
  @media only screen and (max-width: $mobile) {
    justify-content: center;
    width: 100%;
    margin-bottom: 64px;
  };
}