@use '../../styles/Colors' as *;
@use '../../styles/Breakpoints' as *;

.certifications-wrapper {
  padding: 0px 32px;
  @media only screen and (max-width: $mobile) {
    padding: 0px 13.5px;
  };
}

.certifications-headline {
  text-align: center;
  font-size: 48px;
  line-height: 57px;
  color: $primary;
  margin-top: 64px;
  margin-bottom: 64px;
  font-weight: 300;
  @media only screen and (max-width: $tabletBig) {
    font-size: 36px;
    line-height: 43px;
  };
  @media only screen and (max-width: $mobile) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 32px;
  };
}

.certifications-image {
  background-color: $lightGrey;
  display: flex;
  justify-content: center;
  img {
    margin: 16px;
  };
  @media only screen and (max-width: $mobile) {
    margin-bottom: 32px;
  };
}

.certifications-text {
  color: $darkGrey;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 64px;
  @media only screen and (max-width: $mobile) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  };
}

div.col.s12.l6.certifications-button {
  margin-right: 20px;
  max-width: 300px;
  @media only screen and (max-width: $mobile) {
    max-width: 100%;
  };
}

.certifications-button-wrapper {
  margin-bottom: 32px;
}

.certifications-button {
  background-color: $primary;
  border-radius: 3px;
  display: flex;
  justify-content: space-around;
  color: $white;
  margin: 0px 30px 32px 0px;
  cursor: pointer;
  @media only screen and (max-width: $mobile) {
    margin-bottom: 16px;
  };
}

.certifications-button-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 14px 20px;
}

.certifications-bottom-line {
  border-bottom: 3px solid $lightGrey;
  // &:last-child {
  //   border-bottom: unset;
  // }
}